<template>
  <div>
    <Nav></Nav>
    <b-container fluid class="mt-3">
      <b-row>
        <b-col>
          <h1>Tipos de Movimientos de Caja</h1>
        </b-col>
      </b-row>
      <b-row class="text-left my-2">
        <b-col>
          <b-button variant="success" @click="$bvModal.show('modal-newProvider')" size="sm mr-3">Crear Tipo
            +</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Buscar:">
            <b-form-input type="search" v-model="search" @keyup="page = 1" debounce="500"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table small :filter="search" striped hover :items="supply_list" :fields="comprasLabels"  responsive>
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(status)="data">
              <b-badge :variant="statusBadge(data.item.sts).variant">{{ statusBadge(data.item.sts).text
                }}</b-badge>
            </template>
            <template #cell(buttons)="data">
              <b-button size="sm" variant="success"
                @click="getProvider(data.item.id); $bvModal.show('modal-newProvider')" class="mr-1">
                +Info
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-container>
    <b-modal id="modal-newProvider" @close="emptyNewItem" :hide-footer="true" size="xl"
      :title="(newItem.id > 0 ? 'Editar' : 'Agregar') + ' Tipo de Movimiento de Caja'">
      <b-row>
        <b-col lg="6" cols="12">
          <b-form-group label="Descripcion" >
            <b-form-input v-model="newItem.description" placeholder="Nombre" required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col lg="6" cols="12">
          <b-form-group label="Entrada/Salida" >
            <b-form-select v-model="newItem.in_out">
              <b-form-select-option :value="1">Entrada</b-form-select-option>
              <b-form-select-option :value="2">Salida</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col lg="6" cols="12">
          <b-form-group label="Status">
            <b-form-checkbox v-model="newItem.sts" :value="true"
              :unchecked-value="false">
              Activo/Inactivo
            </b-form-checkbox> </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <p>Puedes Habilitar hasta tres referencias:</p>
        </b-col>
      </b-row>
     <b-card>
      <b-row>
        <b-col cols="12">
          <p>Referencia1:</p>
        </b-col>
        <b-col lg="12" cols="12">
          <b-form-group>
            <b-form-checkbox v-model="newItem.ref1_available" :value="true"
              :unchecked-value="false">
              Activo/Inactivo
            </b-form-checkbox> </b-form-group>
        </b-col>
        <b-col v-show="newItem.ref1_available" lg="6" cols="12">
          <b-form-group label="Texto">
            <b-form-input v-model="newItem.ref1_text" required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col v-show="newItem.ref1_available" lg="6" cols="12">
          <b-form-group label="Tipo de control" >
            <b-form-select v-model="newItem.ref1_type">
              <b-form-select-option value="text">Texto</b-form-select-option>
              <b-form-select-option value="number">Numero</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
     </b-card>
     <b-card>
      <b-row>
        <b-col cols="12">
          <p>Referencia 2:</p>
        </b-col>
        <b-col lg="12" cols="12">
          <b-form-group >
            <b-form-checkbox v-model="newItem.ref2_available" :value="true"
              :unchecked-value="false">
              Activo/Inactivo
            </b-form-checkbox> </b-form-group>
        </b-col>
        <b-col v-show="newItem.ref2_available" lg="6" cols="12">
          <b-form-group label="Texto">
            <b-form-input v-model="newItem.ref2_text" required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col v-show="newItem.ref2_available" lg="6" cols="12">
          <b-form-group label="Tipo de control" >
            <b-form-select v-model="newItem.ref2_type">
              <b-form-select-option value="text">Texto</b-form-select-option>
              <b-form-select-option value="number">Numero</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
     </b-card>
     <b-card>
      <b-row>
        <b-col cols="12">
          <p>Referencia 3:</p>
        </b-col>
        <b-col lg="12" cols="12">
          <b-form-group>
            <b-form-checkbox v-model="newItem.ref3_available" :value="true"
              :unchecked-value="false">
              Activo/Inactivo
            </b-form-checkbox> </b-form-group>
        </b-col>
        <b-col v-show="newItem.ref3_available" lg="6" cols="12">
          <b-form-group label="Texto">
            <b-form-input v-model="newItem.ref3_text" required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col v-show="newItem.ref3_available" lg="6" cols="12">
          <b-form-group label="Tipo de control" >
            <b-form-select v-model="newItem.ref3_type">
              <b-form-select-option value="text">Texto</b-form-select-option>
              <b-form-select-option value="number">Numero</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
     </b-card>
      <b-row>
        <b-col class="my-3">
          <b-button class="mr-3" @click="createNewItem" variant="success">Guardar</b-button>
          <b-button class="mr-3" @click="$bvModal.hide('modal-newProvider'); emptyNewItem();"
            variant="danger">Cancelar</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
import Nav from "../components/Nav.vue";


export default {
  name: "PaydeskMovements",
  components: {
    Nav
  },

  data() {
    return {
      newItem: {
        description: '',
        sts:false,
        in_out:null,

        ref1_available:false,
        ref1_text:"",
        ref1_type:"",

        ref2_available:false,
        ref2_text:"",
        ref2_type:"",

        ref3_available:false,
        ref3_text:"",
        ref3_type:"",
        
      },
      search: "",
      supply_list: [],
      comprasLabels: [
        { key: "id", label: "ID" },
        { key: "description", label: "Nombre" },
        { key: "status", label: "Status" },
        { key: "buttons", label: "" }
      ]
    };
  },

  computed: {

  },
  watch: {},
  mounted() {
    this.getLists()
  },
  created() {

  },
  methods: {
    statusBadge(status) {
      let rtn = { variant: "", text: status }
      switch (status) {
        case true:
          rtn.variant = "success"
          rtn.text = "Activo"
          break;
        case false:
          rtn.variant = "danger"
          rtn.text = "Inactivo"
          break;
      }
      return rtn
    },
    createNewItem() {
      let that = this
      let elm = this.newItem
      if (!this.newItem.is_internal){
        this.newItem.sucursal_id=null
      }
      if(this.newItem.id>0){
        axios.put("/paydesk/movements/type/"+this.newItem.id, elm)
        .then(function (res) {
          if (res.data.id > 0) {
            that.getLists()
            that.emptyNewItem()
            that.$bvModal.hide("modal-newProvider")
          }
        }).catch(function (err) {
          that.makeToast("danger", "Error", err.response.data)
        })
      }else{
        axios.post("/paydesk/movements/type/", elm)
        .then(function (res) {
          if (res.data.id > 0) {
            that.getLists()
            that.emptyNewItem()
            that.$bvModal.hide("modal-newProvider")
          }
        }).catch(function (err) {
          that.makeToast("danger", "Error", err.response.data)
        })
      }
      

    },
    emptyNewItem() {
      this.newItem = {
        name: '',
        description: ''
      }
    },
    getProvider(id) {
      let that = this
      axios.get("/paydesk/movements/type/" + id)
        .then(function (res) {
          that.newItem = res.data
        }).catch(function (err) {
          console.error(err)
        })
    },
    getLists() {
      let that = this
      axios.get("/paydesk/movements/types")
        .then(function (res) {
          that.supply_list = res.data
        }).catch(function (err) {
          console.error(err)
        })
    },
    makeToast(variant = null, title = "", text = "") {
      this.$bvModal.msgBoxOk(text, {
        title: title,
        okVariant: variant,
        centered: true
      })
    },
  },
};
</script>
