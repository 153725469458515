<template>
  <div>
    <Nav> </Nav>
    <b-container fluid class="mt-3">
      <ReportSearchBar :emitOnChange="false" @search="searchRecv"></ReportSearchBar>
      <b-row>
        <b-col>
          <h3>Ventas</h3>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6" sm="6" lg="4" xl="2">
          <b-card no-body class="bg-gradient-default">
            <!-- Card body -->
            <b-card-body>
              <b-row>
                <b-col>
                  <b-card-title title-tag="h5" class="text-uppercase text-muted mb-0 text-white">Ventas
                    Totales</b-card-title>
                  <span class="h2 font-weight-bold mb-0 text-white">{{ '$' + ventasSum.toLocaleString('en-US') }}</span>
                </b-col>
                <b-col md="auto">

                </b-col>
              </b-row>
            </b-card-body>
          </b-card>

        </b-col>
        <b-col cols="6" sm="6" lg="4" xl="2">
          <b-card no-body class="bg-gradient-default">
            <!-- Card body -->
            <b-card-body>
              <b-row>
                <b-col>
                  <b-card-title title-tag="h5" class="text-uppercase text-muted mb-0 text-white">Ticket
                    promedio</b-card-title>
                  <span class="h2 font-weight-bold mb-0 text-white">{{ '$' + avgTicket.toLocaleString('en-US') }}</span>
                </b-col>
                <b-col md="auto">

                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>

        <b-col cols="6" sm="6" lg="4" xl="2">
          <b-card no-body class="bg-gradient-default">
            <!-- Card body -->
            <b-card-body>
              <b-row>
                <b-col>
                  <b-card-title title-tag="h5" class="text-uppercase text-muted mb-0 text-white">Promedio x
                    persona</b-card-title>
                  <span class="h2 font-weight-bold mb-0 text-white">{{ '$' + avgTicketPp.toLocaleString('en-US')
                    }}</span>
                </b-col>
                <b-col md="auto">

                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>

        <b-col cols="6" sm="6" lg="4" xl="2">
          <b-card no-body class="bg-gradient-default">
            <!-- Card body -->
            <b-card-body>
              <b-row>
                <b-col>
                  <b-card-title title-tag="h5" class="text-uppercase text-muted mb-0 text-white"># de
                    cuentas</b-card-title>
                  <span class="h2 font-weight-bold mb-0 text-white">{{ ticketsSum.toLocaleString('en-US') }}</span>
                </b-col>
                <b-col md="auto">

                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="6" sm="6" lg="4" xl="2">
          <b-card no-body class="bg-gradient-default border-0">
            <!-- Card body -->
            <b-card-body>
              <b-row>
                <b-col>
                  <b-card-title title-tag="h5" class="text-uppercase text-muted mb-0 text-white"># de
                    comensales</b-card-title>
                  <span class="h2 font-weight-bold mb-0 text-white">{{ comensales.toLocaleString('en-US') }}</span>
                </b-col>
                <b-col md="auto">

                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="6" sm="6" lg="4" xl="2">
          <b-card no-body class="bg-gradient-default border-0">
            <!-- Card body -->
            <b-card-body>
              <b-row>
                <b-col>
                  <b-card-title title-tag="h5" class="text-uppercase text-muted mb-0 text-white">Comensales
                    promedio</b-card-title>
                  <span class="h2 font-weight-bold mb-0 text-white">{{ avgcomensales.toLocaleString('en-US') }}</span>
                </b-col>
                <b-col md="auto">

                </b-col>
              </b-row>
            </b-card-body>
          </b-card>

        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h3>Metas</h3>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6" sm="6" lg="4" xl="2">
          <b-card no-body class="bg-gradient-default">
            <!-- Card body -->
            <b-card-body>
              <b-row>
                <b-col>
                  <b-card-title title-tag="h5" class="text-uppercase text-muted mb-0 text-white">Meta</b-card-title>
                  <span class="h2 font-weight-bold mb-0 text-white">${{ metadelPeriodo.toLocaleString('en-US')}}</span>
                </b-col>
                <b-col md="auto">
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="6" sm="6" lg="4" xl="2">
          <b-card no-body :bg-variant="attainment.percentage>=100?'success':attainment.percentage<80?'danger':'warning'">
            <!-- Card body -->
            <b-card-body>
              <b-row>
                <b-col>
                  <b-card-title title-tag="h5" class="text-uppercase text-muted mb-0 text-white">Porcentaje Alcanzado</b-card-title>
                  <span class="h2 font-weight-bold mb-0 text-white">{{ attainment.percentage.toLocaleString('en-US')
                    }} %</span>
                </b-col>
                <b-col md="auto">

                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
        
      </b-row>
      <b-row>
        <b-col cols="12" md="12" lg="9">
          <b-card>
            <b-row>
              <b-col>
                <h3>Ventas Totales por {{ group_type }} {{ show_initDate }} al {{ show_lastDate }} </h3>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <LineChart :show="nsalesChart.show" :datasets="nsalesChart.datasets" :labels="nsalesChart.labels">
                </LineChart>
              </b-col>
            </b-row>

          </b-card>
        </b-col>
        <b-col cols="12" md="12" lg="3">
          <b-card>
            <b-row>
              <b-col>
                <h3>Ventas por Sucursal {{ show_initDate }} al {{ show_lastDate }} </h3>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <DoughnutChart :show="salesBySucursalDonutChart.show" :datasets="salesBySucursalDonutChart.datasets"
                  :labels="salesBySucursalDonutChart.labels">
                </DoughnutChart>
              </b-col>
            </b-row>


          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col v-if="allSucsArray.length > 1" cols="12" md="12" lg="9">
          <b-card>
            <b-row>
              <b-col>
                <h3>Ventas por {{ group_type }} {{ show_initDate }} al {{ show_lastDate }} </h3>

              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <LineChart :datasets="salesBySucursalLineChart.datasets" :show="salesBySucursalLineChart.show"
                  :options="salesBySucursalLineChart.options" :labels="salesBySucursalLineChart.labels">
                </LineChart>
              </b-col>
            </b-row>

          </b-card>
        </b-col>
        <b-col cols="12" md="12" lg="3">
          <b-card>
            <b-row>
              <b-col>
                <h3>Comensales por Sucursal {{ show_initDate }} al {{ show_lastDate }} </h3>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <DoughnutChart :show="comensalesBySucursalDonutChart.show"
                  :datasets="comensalesBySucursalDonutChart.datasets" :labels="comensalesBySucursalDonutChart.labels">
                </DoughnutChart>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col cols="12" md="6" lg="3">
          <b-card no-body class="p-3">
            <h3>Por dia</h3>
            <b-table small striped hover sticky-header="50vh" :fields="tableSales.headers" :items="tableSales.data"></b-table>
          </b-card>
        </b-col>
        <b-col cols="12" md="6" lg="9">
          <b-card no-body class="p-3">
            <h3>Popularidad {{ show_initDate }} al {{ show_lastDate }} </h3>
            <b-table small striped sort-by="quantity" :sort-desc="true" hover sticky-header="50vh" :per-page="pop_byPage"
              :current-page="pop_page" :fields="tablePopularidad.headers" :items="tablePopularidad.data"></b-table>

            <b-pagination v-model="pop_page" :total-rows="tablePopularidad.data.length" :per-page="pop_byPage"
              aria-controls="my-table"></b-pagination>

          </b-card>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>


<script>
import axios from "axios";
import Nav from "../components/Nav.vue";
import moment from 'moment';
// import { D3BarChart } from 'vue-d3-charts';
import ReportSearchBar from "./reports/searchBar/ReportSearchBar.vue";
import DoughnutChart from "../components/Graphs/DoughnutChart.vue"
import LineChart from "../components/Graphs/LineChart.vue";
import _ from 'lodash';
export default {
  name: "Dashboard",
  components: { Nav, ReportSearchBar, DoughnutChart, LineChart },
  watch: {
  },
  data() {
    return {
      projectedSales: [],
      show_initDate: "",
      show_lastDate: "",
      group_type: "daily",
      pop_byPage: 11,
      pop_page: 1,
      loading: false,
      daysCount: 0,
      allSucsArray: [],
      weekLbls: [],
      monthLbls: [],
      comensales: 0,
      ventasSum: 0,
      ticketsSum: 0,
      avgTicket: 0,
      avgTicketPp: 0,
      avgcomensales: 0,
      metadelPeriodo: 0,
      attainment: {
        percentage: 0
      },
      tableSales: {
        data: [],
        headers: [
          { key: "fecha", label: "Fecha", sortable: true },
          { key: "total", label: "Total", sortable: true }
        ]
      },
      tablePopularidad: {
        data: [],
        headers: [
          { key: "ndescription", label: "Nombre", sortable: true },
          { key: "quantity", label: "Cantidad", sortable: true },
          { key: "restaurant_area_name", label: "Area", sortable: true },
        ]
      },
      colorsArray: [
        "#FF5733", "#FFC300", "#FFDC00", "#F4D03F", "#28B463",
        "#3498DB", "#5DADE2", "#85C1E9", "#A569BD", "#F1948A",
        "#D98880", "#CD6155", "#EC7063", "#AF7AC5", "#A569BD",
        "#5499C7", "#5DADE2", "#48C9B0", "#45B39D", "#52BE80",
        "#58D68D", "#F7DC6F", "#F4D03F", "#FF5733", "#F5B041",
        "#DC7633", "#BA4A00", "#E74C3C", "#C0392B", "#922B21",
        "#6E2C00", "#AF601A", "#935116", "#784212", "#5B2C6F",
        "#4A235A", "#7D3C98", "#5B2C6F", "#4A235A", "#7D3C98",
        "#5B2C6F", "#4A235A", "#7D3C98", "#5B2C6F", "#4A235A",
        "#7D3C98", "#5B2C6F", "#4A235A", "#7D3C98", "#5B2C6F",
      ],
      sucursales: [],
      salesBySucursal: [],
      salesReport: [],
      comensalesBySucursalDonutChart: {
        show: true,
        datasets: [{
          data: [],
          borderColor: '#fafafa',
          backgroundColor: [
          ],
          borderWidth: 2
        }],
        labels: []
      },
      salesBySucursalDonutChart: {
        show: true,
        datasets: [{
          data: [],
          borderColor: '#fafafa',
          backgroundColor: [
          ],
          borderWidth: 2
        }],
        labels: []
      },
      salesBySucursalLineChart: {
        show: true,
        datasets: [],
        labels: [],
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              type: 'time',
            },
          }
        }
      },
      nsalesChart: {
        show: true,
        datasets: [{
          label: 'Ventas Totales',
          data: [],
          fill: false,
          borderColor: '#172b4d',
          backgroundColor: '#172b4d',
          borderWidth: 5
        }, {
          label: 'Proyectado',
          data: [],
          fill: false,
          borderColor: '#2dce89',
          backgroundColor: '#2dce89',
          borderWidth: 5
        }
        ],
        labels: []
      },
      salesChart: {
        chart_title: "Ventas",
        chartData: [],
        config: {
          key: "date",
          values: ["total"],
          tooltip: { label: "total" },
          labelRotation: 45,
          color: {
            default: '#222f3e',
            current: '#41B882'
          }
        },
      },
      initialDate: null,
      lastDate: null,
      minLastDate: null
    };
  },
  mounted() {
    this.getSucursals()
    moment().locale("es-mx")
    this.initialDate = moment().subtract(7, "days").format()
    this.lastDate = moment().add(1, "days").format()
    this.minLastDate = moment(this.initialDate).add(1, 'days').format()
    // let that = this
    // setTimeout(function () { that.changeDate() }, 300)
  },
  methods: {
    searchRecv(p) {
      console.log("valchanged")
      console.log(p)
      this.initialDate = p.initDate
      this.lastDate = p.endDate
      let i = moment(p.initDate)
      let e = moment(p.endDate)
      this.daysCount = e.diff(i, 'days')
      if (this.daysCount > 90) {
        this.group_type = "monthly"
      } else if (this.daysCount > 30) {
        this.group_type = "weekly"
      } else if (this.daysCount > 0) {
        this.group_type = "daily"
      }
      this.allSucsArray = p.sucursales
      this.changeDate()
    },
    setDateRange() {
      let firstDate = this.initialDate, lastDate = this.lastDate
      let date = moment(firstDate)
      const dates = [date.format('YYYY-MM-DD')];
      do {
        date = moment(date).add(1, 'day');
        dates.push(date.format('YYYY-MM-DD'));
      } while (moment(date).isBefore(lastDate));
      this.salesBySucursalLineChart.show = false
      this.salesBySucursalLineChart.labels = dates
      this.nsalesChart.labels = dates

      let initDate = moment(firstDate)
      let endDate = moment(lastDate)

      this.show_initDate = initDate.format('YYYY-MM-DD')
      this.show_lastDate = endDate.format('YYYY-MM-DD')

      let months = endDate.diff(initDate, "months")
      let moLbls = []
      let yr = initDate.year()
      let mo = initDate.month()
      for (var i = 0; i < months; i++) {
        if (mo > 12) {
          mo = 1
          yr++
        }
        moLbls.push(yr + "-" + mo)
        mo++
      }
      this.monthLbls = moLbls

      let weeks = endDate.diff(initDate, "weeks")
      let wkLbls = []
      yr = initDate.year()
      let wk = initDate.week()
      for (i = 0; i < weeks; i++) {
        let wksinYr = moment(yr).weeksInYear()
        if (wk > wksinYr) {
          yr++
          wk = 1
        }
        wkLbls.push(yr + "-" + wk)
        wk++
      }
      this.weekLbls = wkLbls
      console.log("Date SET")
    },
    calculateAttainment() {
      this.attainment.percentage = this.ventasSum * 100 / this.metadelPeriodo
    },
    getProjectedReport() {
      console.log("getProjectedReport")
      this.loading = true
      let that = this
      that.nsalesChart.show = false
      that.nsalesChart.datasets[1].data = []
      axios.post("/report/projections", { initial_date: moment(this.initialDate).format("YYYY-MM-DD"), end_date: moment(this.lastDate).subtract(1, 'day').format("YYYY-MM-DD"), sucursales: this.allSucsArray })
        .then(function (r) {
          console.log("report got getProjectedReport")
          let d = r.data.map(function (r) {
            let m = moment(r.date)
            return { ...r, week: m.isoWeek(), year: m.year(), month: parseInt(m.format("M")), weekyear: m.year() + "-" + m.isoWeek(), yearmonth: m.year() + "-" + m.format("M") }
          })
          that.projectedSales = d
          let grouped = Object.groupBy(r.data, ({ date }) => date)
          let showable = []
          Object.keys(grouped).forEach(key => {
            let r = grouped[key]
            let total = r.reduce((p, a) => p + a.amount, 0)
            showable.push({ date: key, total })
          })
          let l = showable.sort(
            (a, b) => new Date(a.date) - new Date(b.date),
          )
          let yaxis = []
          let xaxis = []
          let dtset = []
          let meta = 0
          l.forEach(element => {
            yaxis.push(element.total)
            xaxis.push(element.date)
            dtset.push({ x: element.date, y: element.total })
            meta += element.total
          });
          that.metadelPeriodo = meta
          that.calculateAttainment()
          that.nsalesChart.datasets[1].data = dtset
          that.nsalesChart.show = true
          that.loading = false
          console.log("getProjectedReport report done")
        }).catch(function (e) {
          console.error(e.response)
        })

    },
    getPopularidad() {
      this.pop_page = 1
      let that = this
      that.tablePopularidad.data = []
      let queryparam = { initial_date: moment(that.initialDate).format("YYYY-MM-DD"), end_date: moment(that.lastDate).format("YYYY-MM-DD"), sucursales: this.allSucsArray }
      console.log(queryparam)
      axios
        .post("/report/popularidad", queryparam)
        .then(function (response) {
          that.tablePopularidad.data = response.data;
          console.log("get popularidad")
          console.log(queryparam)
          console.log(response.data)
        })
        .catch(function (err) {
          console.error(err);
          that.makeToast("danger", "Error", err.response.data);
        })
    },
    getsalesreport() {
      console.log("getSalesreport")
      this.loading = true
      let that = this
      this.salesReport = []
      that.nsalesChart.show = false
      that.nsalesChart.datasets[0].data = []
      that.ticketsSum = 0
      that.avgTicketPp = 0
      that.avgTicket = 0
      that.avgcomensales = 0
      axios.post("/report/dates", { initial_date: moment(this.initialDate).format("YYYY-MM-DD"), end_date: moment(this.lastDate).format("YYYY-MM-DD"), sucursales: this.allSucsArray })
        .then(function (r) {
          console.log("report got")
          let d = r.data.map(function (r) {
            let m = moment(r.date)
            return { ...r, week: m.isoWeek(), year: m.year(), month: parseInt(m.format("M")), weekyear: m.year() + "-" + m.isoWeek(), yearmonth: m.year() + "-" + m.format("M") }
          })
          that.salesReport = d
          that.setVentasPorSucursal()
          that.setVentasPorsucursalPorDia()
          let comen = r.data.reduce(function (p, a) {
            return p + a.comensales
          }, 0)
          that.comensales = Math.round((comen + Number.EPSILON) * 100) / 100
          let venSum = r.data.reduce(function (p, a) {
            return p + a.total
          }, 0)
          that.ventasSum = Math.round((venSum + Number.EPSILON) * 100) / 100
          that.calculateAttainment()
          let tSum = r.data.reduce(function (p, a) {
            return p + a.operaciones
          }, 0)
          that.ticketsSum = Math.round((tSum + Number.EPSILON) * 100) / 100
          that.avgTicketPp = Math.round(((that.ventasSum / that.comensales) + Number.EPSILON) * 100) / 100
          that.avgTicket = Math.round(((that.ventasSum / that.ticketsSum) + Number.EPSILON) * 100) / 100
          that.avgcomensales = Math.round(((that.comensales / that.ticketsSum) + Number.EPSILON) * 100) / 100

          that.salesChart.chartData = []
          let grouped = Object.groupBy(r.data, ({ date }) => date)
          let showable = []
          Object.keys(grouped).forEach(key => {
            let r = grouped[key]
            let total = r.reduce((p, a) => p + a.total, 0)
            showable.push({ date: key, total })
          })
          that.salesChart.chartData = showable.sort(
            (a, b) => new Date(a.date) - new Date(b.date),
          )
          let yaxis = []
          that.salesChart.chartData.forEach(element => {
            yaxis.push(element.total)
          });
          that.nsalesChart.datasets[0].data = yaxis
          that.nsalesChart.show = true
          that.tableSales.data = showable.sort(
            (a, b) => new Date(a.date) - new Date(b.date),
          ).map(function (x) {
            return { fecha: x.date, total: "$ " + x.total.toLocaleString('en-US') }
          })
          that.loading = false
          console.log("sales report done")
        }).catch(function (e) {
          console.error(e.response)
        })

    },
    getSucursals() {
      let that = this;
      axios
        .get("/sucursals")
        .then(function (response) {
          that.sucursales = response.data
        })
        .catch(function (err) {
          console.error(err)
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    setVentasPorsucursalPorDia() {
      let that = this
      let base = this.salesReport
      let clrid = 0
      that.salesBySucursalLineChart.datasets = []
      let grouped = _(base).groupBy('sucursal_id')
        .map(function (d, s_id) {
          let lbl = ""
          let suc = _.find(that.sucursales, function (s) {
            return s.id == s_id
          })
          if (suc != undefined) {
            lbl = suc.nombre
          } else {
            lbl = ""
          }
          if (clrid >= that.colorsArray.length) {
            clrid = 0
          }
          let clr = that.colorsArray[clrid]
          clrid++
          if (that.group_type == "monthly") {
            return {
              borderColor: clr,
              backgroundColor: clr,
              label: lbl,
              data: _.orderBy(_(d).groupBy('yearmonth')
                .map(function (s, yearmonth) {
                  let dt = yearmonth
                  return {
                    x: dt,
                    e: s,
                    month: _.maxBy(s, 'month').month,
                    year: _.maxBy(s, 'year').year,
                    //sucursal_id: s_id,
                    y: _.sumBy(s, 'total'),
                    //comensales: _.sumBy(s, 'comensales')
                  }
                }).value(), ["year", "month"], ["asc", "asc"]), sucursal_id: s_id
            }
          }
          if (that.group_type == "weekly") {
            return {
              borderColor: clr,
              backgroundColor: clr,
              label: lbl,
              data: _.orderBy(_(d).groupBy('weekyear')
                .map(function (s, weekyear) {
                  let dt = weekyear
                  return {
                    x: dt,
                    e: s,
                    week: _.maxBy(s, 'week').week,
                    year: _.maxBy(s, 'year').year,
                    //sucursal_id: s_id,
                    y: _.sumBy(s, 'total'),
                    //comensales: _.sumBy(s, 'comensales')
                  }
                }).value(), ["year", "week"], ["asc", "asc"]), sucursal_id: s_id
            }
          }
          return {
            borderColor: clr,
            backgroundColor: clr,
            label: lbl,
            data: _(d).groupBy('date')
              .map(function (s, date) {
                return {
                  x: date,
                  //sucursal_id: s_id,
                  y: _.sumBy(s, 'total'),
                  //comensales: _.sumBy(s, 'comensales')
                }
              }).value(), sucursal_id: s_id
          }
        }).value()
      if (that.group_type == "weekly") {
        that.salesBySucursalLineChart.labels = that.weekLbls
      }
      if (that.group_type == "monthly") {
        that.salesBySucursalLineChart.labels = that.monthLbls
      }
      // that.salesBySucursalLineChart.labels=lbls
      that.salesBySucursalLineChart.datasets = grouped
      that.salesBySucursalLineChart.show = true
    },
    setVentasPorSucursal() {
      let that = this
      this.salesBySucursalDonutChart.show = false
      this.comensalesBySucursalDonutChart.show = false
      this.salesBySucursalDonutChart.labels = []
      this.salesBySucursalDonutChart.datasets[0].data = []
      let base = this.salesReport
      let grouped = _(base).groupBy('sucursal_id')
        .map((sucursal, id) => {
          return {
            sucursal_id: id,
            total: _.sumBy(sucursal, 'total'),
            comensales: _.sumBy(sucursal, 'comensales')
          }
        })
        .value()
      let l = []
      let a1 = []
      let a2 = []
      _.forEach(grouped, function (v) {
        let suc = _.find(that.sucursales, function (s) {
          return s.id == v.sucursal_id
        })
        if (suc != undefined) {
          l.push(suc.nombre)
        } else {
          l.push("")
        }
        a1.push(v.total)
        a2.push(v.comensales)
      })
      this.salesBySucursalDonutChart.labels = l
      this.salesBySucursalDonutChart.datasets[0].data = a1
      this.salesBySucursalDonutChart.datasets[0].backgroundColor = this.colorsArray
      this.comensalesBySucursalDonutChart.labels = l
      this.comensalesBySucursalDonutChart.datasets[0].data = a2
      this.comensalesBySucursalDonutChart.datasets[0].backgroundColor = this.colorsArray
      this.salesBySucursalDonutChart.show = true
      this.comensalesBySucursalDonutChart.show = true
      // this.salesBySucursalDonutChart.datasets[0].backgroundColor = clrs
      // this.salesBySucursalDonutChart.datasets[0].borderColor = clrs
    },
    selectColor(colorNum, colors) {
      if (colors < 1) colors = 1; // defaults to one color - avoid divide by zero
      return "hsl(" + (colorNum * (360 / colors) % 360) + ",100%,50%)";
    },
    changeDate() {
      this.setDateRange()
      this.getsalesreport()
      this.getProjectedReport()
      this.getPopularidad()
    },
    makeToast(title, content, variant) {
      this.$bvToast.toast(content, {
        title,
        variant,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
  }
}
</script>