<template>
  <div>
    <Nav></Nav>
    
      <b-row>
        <b-col>
          <h1>Facturas Generadas</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button variant="default" @click="$router.push('/factura')">Crear Factura</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Buscar:">
            <b-form-input
              type="search"
              v-model="search"
              @keyup="page = 1"
              debounce="500"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table small
            striped
            hover
            :items="facturas"
            :fields="tableFields"
            responsive
            :per-page="byPage"
            :current-page="page"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            
            <template #cell(buttons)="data">
              <b-button-group >
                <b-button size="sm" variant="info" :href="'https://rigadapos-facturas.s3.us-east-2.amazonaws.com/' + data.item.uid+'.pdf'"
                  >PDF</b-button
                >
                <b-button size="sm" variant="success" :href="'https://rigadapos-facturas.s3.us-east-2.amazonaws.com/' + data.item.uid+'.xml'"
                  >XML</b-button
                >
                <b-button size="sm" variant="primary" @click="resendFacturaId=data.item.id;$refs.reenviaFactura.openModal();"
                  >Reenviar</b-button
                >
                <b-button size="sm" variant="danger"  @click="cancelFacturaId=data.item.id;$refs.cancelaFactura.openModal();"
                  >Cancelar</b-button
                >
              </b-button-group>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-pagination
            v-model="page"
            :total-rows="facturas.length"
            :per-page="byPage"
            aria-controls="my-table"
          ></b-pagination>
        </b-col>
      </b-row>
      <ReenviarFactura ref="reenviaFactura" :factura_id="resendFacturaId" ></ReenviarFactura>
      <CancelaFactura ref="cancelaFactura" :factura_id="cancelFacturaId" ></CancelaFactura>

  </div>
</template>
<script>
import Nav from "../components/Nav.vue";
import axios from "axios";
import ReenviarFactura from "../components/ReenviarFactura.vue"
import CancelaFactura from "../components/CancelaFactura.vue";
export default {
  name: "facturas",
  components: { Nav, ReenviarFactura, CancelaFactura},
  watch: {
    search(){
      this.getFacturas()
    }
  },
  data() {
    return {
      resendFacturaId:null,
      cancelFacturaId:null,
      byPage: 20,
      page: 1,
      search: "",
      facturas: [],
      tableFields: [
        { key: "id", label: "ID", sortable: true, sortDirection: "asc" },
        { key: "sucursal_id", label: "Sucursal", sortable: true },
        { key: "total", label: "Total", sortable: true },
        { key: "rfc_receptor", label: "RFC", sortable: true },
        { key: "razon_social_receptor", label: "Razon Social", sortable: true },
        { key: "folio", label: "Folio", sortable: true },
        { key: "version", label: "Version", sortable: true },
        { key: "total", label: "Total", sortable: true },
        { key: "fecha_timbrado", label: "Fecha", sortable: true },
        { key: "status" },
        { key: "buttons" },
      ],
    };
  },
  mounted() {
    this.getFacturas();
  },
  methods: {
    cleanNewUser() {
      this.newuser.name = "";
      this.newuser.password = "";
      this.newuser.email = "test@mail.com";
      this.newuser.username = "";
      this.newuser.cellphone = "5511223344";
    },
    newUser(event) {
      event.preventDefault();
      let that = this;
      axios
        .post("/user", this.newuser)
        .then(function (data) {
          that.makeToast(
            "Exito",
            data.response.username + " creado",
            "success"
          );
          that.cleanNewUser();
          that.getAllUsers();
          that.search = data.response.username;
        })
        .catch(function (err) {
          console.log(err.request.response);
          that.makeToast("Error", err.request.response, "danger");
        });
    },

    searchGroup(id) {
      return (
        this.facturas.find((element) => element.id == id) || { name: "error" }
      );
    },
    getFacturas() {
      let that = this;
      let xtraSearch=""
      if(this.search.length>0){
        xtraSearch="?search="+this.search
      }
      axios
        .get("/company/1/facturas"+xtraSearch)
        .then(function (response) {
          that.facturas = response.data;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
   
    makeToast(title, content, variant) {
      this.$bvToast.toast(content, {
        title,
        variant,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
  },
};
</script>
