<template>
  <div>
    <Nav></Nav>
    <b-container fluid>
      <b-row>
        <b-col class="text-left">
          <h1>{{ actualBom.name }}<p>ID: {{ actualBom.id }}</p>
          </h1>
        </b-col>
      </b-row>
      <b-card class="my-2 p-1">
        <b-row>
          <b-col lg="6" md="12">
            <table class="table table-borderless text-left table-sm">
              <tbody>
                <tr>
                  <td><b>Cantidad:</b></td>
                  <td>{{ actualBom.product_qty }}</td>
                </tr>
                <tr>
                  <td><b>Precio por unidad:</b></td>
                  <td>{{ unitPrice }}</td>
                </tr>
                <tr>
                  <td><b>Status:</b></td>
                  <td><b-badge :variant="actualBom.active ? 'success' : 'danger'">{{
                    actualBom.active ? 'ACTIVO' : 'INACTIVO' }}</b-badge></td>
                </tr>
              </tbody>
            </table>
          </b-col>
          <b-col lg="6" md="12">
            <table class="table table-borderless text-left table-sm">
              <tbody>
                <tr>
                  <td><b>Fecha de Creación:</b></td>
                  <td>{{ actualBom.create_date }}</td>
                </tr>
                <tr>
                  <td><b>Creado Por:</b></td>
                  <td>{{ actualBom.create_uid }}</td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col>
            <b-table ref="selectableTable" small striped hover :items="actualBomItems" :fields="bomsLabels" responsive> <!-- :fields="bomsLabels"-->
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template #cell(active)="data">
                <b-badge :variant="data.item.active ? 'success' : 'danger'">{{ data.item.active ? 'Activo' : 'Inactivo'
                  }}</b-badge>
              </template>
              <template #cell(buttons)="data">
                <b-button size="sm" variant="success" @click="$router.push('/bom/' + data.item.id)" class="mr-1">
                  +Info
                </b-button>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-card>
    </b-container>
  </div>
</template>
<script>
import axios from "axios";
import Nav from "../components/Nav.vue";
export default {
  name: "Supply_bom",
  components: {
    Nav
  },

  data() {
    return {
      supplies: [],
      unitPrice:0,
      actualBom: {
        bom_id: null,
        company_id: null,
        create_date: null,
        create_uid: null,
        date_finished: null,
        date_planned_finished: null,
        date_planned_start: null,
        date_start: null,
        id: null,
        location_dest_id: null,
        location_src_id: null,
        name: null,
        product_qty: null,
        qty_producing: null,
        state: null,
        sucursal_id: null,
        supply_id: null,
        write_date: null,
        write_uid: null
      },
      actualBomItems: [],
      newItem: {
        id: null,
        purchase_order_id: null,
        supply_id: null,
        name: "",
        discount: 0,
        price_unit: 0,
        price_subtotal: 0,
        price_tax: 0,
        price_total: 0,
        product_quantity: 1,
        qty_invoiced: 0,
        qty_received: 0,
        qty_to_invoice: 0,
      },
      bomsLabels: [
        { key: "id", label: "ID" },
        { key: "supply_id", label: "Insumo" , sortable: true},
        { key: "supply.code", label: "Codigo" , sortable: true},
        { key: "supply.name", label: "Nombre" , sortable: true},
        { key: "supply.unit", label: "UD" },
        { key: "product_qty", label: "Cantidad" , sortable: true},
        { key: "supply.price", label: "Precio Actual" , sortable: true},
        { key: "est_price", label: "Total Est. Actual" , sortable: true},
        { key: "buttons", label: "" }
      ]
    };
  },

  computed: {

  },
  watch: {},
  mounted() {
    if (this.$route.params.id > 0) {
      this.actualBom.id = this.$route.params.id;
      this.getCompra()
      this.getCompraItems()
      // this.getSupplies()
      // this.emptyNewItem()
      // this.getShoppingLists()
    }
  },
  created() {

  },
  methods: {
    emptyNewItem() {
      this.newItem = {
        id: null,
        purchase_order_id: this.actualBom.id,
        supply_id: null,
        name: "",
        discount: 0,
        price_unit: null,
        price_subtotal: 0,
        price_tax: 0,
        price_total: 0,
        product_quantity: null,
        qty_invoiced: 0,
        qty_received: 0,
        qty_to_invoice: 0,
      }
    },
    getSupplies() {
      let that = this
      axios.get("/supplies")
        .then(function (res) {
          that.supplies = res.data.map(function (el) {
            el.name = el.code + " - " + el.name
            return el
          })
        }).catch(function (err) {
          that.makeToast("danger", "Error", err.response.data)
        })
    },
    getCompra() {
      let that = this
      axios.get("/bom/" + this.actualBom.id)
        .then(function (res) {
          that.actualBom = res.data
        }).catch(function (err) {
          that.makeToast("danger", "Error", err.response.data)
        })
    },
    getCompraItems() {
      let that = this
      axios.get("/bom/" + this.actualBom.id + "/lines")
        .then(function (res) {
          that.actualBomItems = res.data
          let totalPrice=0
          that.actualBomItems.forEach(function (el) {
            totalPrice += el.est_price
          })
          that.unitPrice=totalPrice/that.actualBom.product_qty
        }).catch(function (err) {
          that.makeToast("danger", "Error", err.response.data)
        })
    },
    createNewItem() {
      let that = this
      this.newItem.bom_id = parseInt(this.actualBom.id)
      let fo_it = this.supplies.find((e) => e.id == this.newItem.supply_id)
      this.newItem.name = fo_it.name
      if (this.newItem.id == null) {
        let elm = this.newItem
        delete elm.id
        axios.post("/purchaseorder/" + this.actualBom.id + "/item", elm)
          .then(function (res) {
            if (res.data.id > 0) {
              that.getCompra()
              that.getCompraItems()
              that.emptyNewItem()
              that.$bvModal.hide("modal-newProduct")
            }
          }).catch(function (err) {
            that.makeToast("danger", "Error", err.response.data)
          })
      } else {
        axios.put("/purchaseorder/" + this.actualBom.id + "/item/" + this.newItem.id, this.newItem)
          .then(function (res) {
            if (res.data.id > 0) {
              that.getCompraItems()
              that.emptyNewItem()
              that.$bvModal.hide("modal-newProduct")
            }
          }).catch(function (err) {
            that.makeToast("danger", "Error", err.response.data)
          })
      }
    },
    makeToast(variant = null, title = "", text = "") {
      if (variant == "success") {
        this.$bvToast.toast(text, {
          title: title,
          variant: variant,
          solid: true,
          appendToast: true,
        });
      } else {
        this.$bvModal.msgBoxOk(text, {
          title: title,
          okVariant: variant,
          centered: true
        })
      }
    },
  },
};
</script>
