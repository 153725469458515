<template>
  <div>
    <Nav></Nav>
    <b-container fluid>
      <b-row>
        <b-col>
          <h1> Analisis de Inventario</h1>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="6" md="12">
          <b-form-group label="Ubicacion">
            <vu-select :reduce="(x) => x.id" label="complete_name" v-model="selectedLocation"
              :options="stockLocations"></vu-select>
          </b-form-group>
        </b-col>
        <b-col lg="6" md="12">
          <b-form-group label="Insumos">
            <vu-select :reduce="(x) => x.id" label="name" multiple v-model="reportInfo.supplies"
              :options="supplies"></vu-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6" md="12">
          <b-form-group label="Fecha Inicial">
            <b-form-datepicker v-model="reportInfo.initial_date"></b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col lg="6" md="12">
          <b-form-group label="Fecha Final">
            <b-form-datepicker v-model="reportInfo.end_date"></b-form-datepicker>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button @click="getReport" variant="success">Consultar</b-button>
        </b-col>
      </b-row>
      <b-row class="my-3">
        <b-col cols="6" sm="6" lg="4" xl="3">
          <b-card no-body class="bg-gradient-danger">
            <!-- Card body -->
            <b-card-body>
              <b-row>
                <b-col>
                  <b-card-title title-tag="h5" class="text-uppercase text-muted mb-0 text-white">Insumos en
                    negativo</b-card-title>
                  <span class="h2 font-weight-bold mb-0 text-white">{{ negativeCount }} / {{ supplies_count }}</span>
                </b-col>
                <b-col md="auto">
                  <b-button variant="success" size="sm" @click="showNegativeSupplies">Mostrar</b-button>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <LineChart :show="reportShow" :datasets="reportTable" :labels="reportLabels" :height="700" >
          </LineChart>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table striped small responsive hover :items="stockAnalysisLines"
            :fields="stockAnalysisLinesFields"  :per-page="50" :current-page="page" ></b-table>
        </b-col>
      </b-row>
      <b-row v-if="stockAnalysisLines">
        <b-col>
          <b-pagination v-model="page" :total-rows="stockAnalysisLines.length" per-page="50"></b-pagination>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import Nav from "../components/Nav.vue";
import axios from "axios";
import moment from 'moment';
import LineChart from "../components/Graphs/LineChart.vue";

import _ from 'lodash';
export default {
  name: "Supplies",
  components: { Nav, LineChart },
  data() {
    return {
      page:1,
      supplies_count:0,
      negativeCount: 0,
      negativeArray: [],
      supplies: [],
      stockLocations: [],
      selectedLocation: null,
      reportInfo: {
        initial_date: null,
        end_date: null,
        supplies: []
      },
      reportTable: [],
      reportLabels: [],
      stockAnalysisLines: [],
      stockAnalysisLinesFields: [
        { key: "movement_date", label: "Fecha", sortable: true },
        { key: "supply_code", label: "Codigo", sortable: true },
        { key: "supply_name", label: "Insumo", sortable: true },
        { key: "supply_unit", label: "UD" },
        { key: "daily_movement", label: "Cantidad", sortable: true },
        { key: "stock_move_origin", label: "Origen", sortable: true },
        { key: "stock_move_reference", label: "Origen", sortable: true },
        { key: "stock_move_name", label: "Nombre", sortable: true },
      ],
      colorsArray: [
        "#FF5733", "#FFC300", "#FFDC00", "#F4D03F", "#28B463",
        "#3498DB", "#5DADE2", "#85C1E9", "#A569BD", "#F1948A",
        "#D98880", "#CD6155", "#EC7063", "#AF7AC5", "#A569BD",
        "#5499C7", "#5DADE2", "#48C9B0", "#45B39D", "#52BE80",
        "#58D68D", "#F7DC6F", "#F4D03F", "#FF5733", "#F5B041",
        "#DC7633", "#BA4A00", "#E74C3C", "#C0392B", "#922B21",
        "#6E2C00", "#AF601A", "#935116", "#784212", "#5B2C6F",
        "#4A235A", "#7D3C98", "#5B2C6F", "#4A235A", "#7D3C98",
        "#5B2C6F", "#4A235A", "#7D3C98", "#5B2C6F", "#4A235A",
        "#7D3C98", "#5B2C6F", "#4A235A", "#7D3C98", "#5B2C6F",
      ],
      reportShow: true
    };
  },
  mounted() {
    this.getAllSupplies();
    this.getAllLocations()
  },
  methods: {
    getAllSupplies() {
      let that = this;
      axios
        .get("/supplies")
        .then(function (response) {
          that.supplies = response.data.map(function (x) {
            x.name = x.code + " - " + x.name
            return x
          })
        })
        .catch(function (err) {
          console.error(err)
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getAllLocations() {
      let that = this;
      axios
        .get("/stocklocations")
        .then(function (response) {
          that.stockLocations = response.data.filter(x => x.type_usage == "internal").map(function (x) {
            x.complete_name = x.id + " - " + x.complete_name
            return x
          })
        })
        .catch(function (err) {
          console.error(err)
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getReport() {
      let that = this;
      console.log(this.reportInfo)
      let firstDate = this.reportInfo.initial_date, lastDate = this.reportInfo.end_date
      let date = moment(firstDate)
      const dates = [date.format('YYYY-MM-DD')];
      do {
        date = moment(date).add(1, 'day');
        dates.push(date.format('YYYY-MM-DD'));
      } while (moment(date).isBefore(lastDate));
      this.reportLabels = dates
      this.reportShow = false
      let clrId = 0
      axios
        .post("/stocklocation/" + this.selectedLocation + "/analyze", this.reportInfo)
        .then(function (response) {
          let spl_c=0
          let ordered = _(response.data).groupBy('supply_name').map(function (d, id) {
            spl_c += 1
            let retDat = _(d).orderBy('fecha').map(function (t) {
              return t.stock_final_dia
            }).value()
            let selColor = that.colorsArray[clrId]
            clrId += 1
            if (clrId >= that.colorsArray.length - 1) {
              clrId = 0
            }
            return {
              borderColor: selColor,
              backgroundColor: selColor,
              label: id,
              data: retDat
            }
          }).value()
          that.supplies_count = spl_c
          that.reportTable = ordered
          that.reportShow = true
          console.log(response.data)
          let negCt = _(response.data).groupBy('estado_inventario').map(function (x, sts) {
            if (sts == "STOCK NEGATIVO") {
              let stock_neg = _(x).groupBy("supply_id").value()
              console.log("stock negativo")
              console.log(stock_neg)
              that.negativeCount = Object.keys(stock_neg).length
              that.negativeArray = _.map(stock_neg, function (x) {
                return x[0].supply_id
              })
            }
            return x
          }).value()
          console.log(negCt)
        })
        .catch(function (err) {
          console.error(err)
          that.makeToast("Error", err.request.response, "danger");
        });
      console.log("/stocklocation/" + this.selectedLocation + "/analyze/lines")
      console.log(this.reportInfo)
      axios
        .post("/stocklocation/" + this.selectedLocation + "/analyze/lines", this.reportInfo)
        .then(function (response) {
          that.stockAnalysisLines = response.data
        })
        .catch(function (err) {
          console.error(err)
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    showNegativeSupplies() {
      let that = this
      let negSupplies = _.filter(this.supplies, function (x) {
        return that.negativeArray.includes(x.id)
      })
      this.reportInfo.supplies = _.map(negSupplies, function (x) {
        return x.id
      })
      this.getReport()
    },
    makeToast(title, content, variant) {
      this.$bvToast.toast(content, {
        title,
        variant,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
  },
};
</script>
