<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col>
          <h2>Items:</h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button size="sm" variant="success" @click="createUberItem">Crear
            Item</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Buscar:">
            <b-form-input type="search" v-model="search" debounce="500"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table small responsive striped hover :filter="search" :items="items" :fields="tableFields">
            <template #cell(buttons)="data">
              <b-button-group vertical>
                <b-button size="sm" variant="warning" @click="editItem(data.item.id)">Editar</b-button>
              </b-button-group>
            </template>
            <template #cell(imageUrl)="data">
              <b-media v-if="data.item.imageUrl.length>0" no-body class="align-items-center">
                <b-img class="avatar" rounded="true" height="3em" alt="Image placeholder" :src="axios.defaults.baseURL + '/' +data.item.imageUrl" />
              </b-media>
            </template>
            <template #cell(modifiers)="data">
              {{ getModifiergroupNamesFromIds(data.item.modifier_group_ids.ids) }}
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-container>
    <b-modal id="modal-createUberItem" title="Crear Categoria" size="xl" @ok="createItem">
      <b-row>
        <b-jumbotron class="p-3 mx-auto" v-if="actualItem.type.length>0">
          <b-row>
            <b-col cols="12" sm="6">
              <b-img fluid v-if="actualItem.image_url!=''" rounded="true"  :src="axios.defaults.baseURL + '/' +actualItem.imageUrl" />
            </b-col>
            <b-col  class="d-flex align-items-center justify-content-center my-3" cols="12" sm="6">
              <b-button @click="show = !show">Editar Imagen</b-button>
            </b-col>
          </b-row>
        </b-jumbotron>
        <my-upload field="image" @crop-success="cropSuccess" @crop-upload-success="cropUploadSuccess"
          @crop-upload-fail="cropUploadFail" v-model="show" lang="es-MX" lang-type="es-MX" :no-circle="true"
           :url="axios.defaults.baseURL +
            '/rappi/item/' +
            this.actualItem.id +
            '/image'
          " :params="imgparams" :headers="imgheaders" img-format="jpg"></my-upload>
      </b-row>
      <b-row>
        <b-col>
          <p>Aqui puedes crear tus productos.</p>
        </b-col>
      </b-row>
      <b-row>
            <b-col md="6" sm="12">
              <b-form-group label="SKU:">
                <b-form-input v-model="actualItem.sku" disabled type="text" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6" sm="12">
              <b-form-group label="Nombre:">
                <b-form-input v-model="actualItem.name" type="text" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col xl="6" md="12">
              <b-form-group label="Descripcion:">
                <b-form-input v-model="actualItem.description" type="text" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col xl="6" md="12">
              <b-form-group label="Precio">
                <b-form-input v-model.number="actualItem.price" type="number" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col xl="4" md="4" sm="12">
              <b-form-group label="Maximo a vender" description="0 si no hay maximo"
              >
                <b-form-input v-model.number="actualItem.maxLimit" type="number" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col xl="4" md="4" sm="12">
              <b-form-group label="Status:">
                <b-form-select v-model="actualItem.status" :options="statusoptions"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col xl="4" md="4" sm="12">
              <b-form-group label="Producto">
                <vu-select :reduce="(x) => x.id" v-model="actualItem.product_id" :options="productOptions"></vu-select>
              </b-form-group>
            </b-col>
            <b-col xl="4" md="4" sm="12">
              <b-form-group label="Categoria">
                <!-- https://vue-select.org/guide/validation.html#required -->
                <vu-select :reduce="(x) => x.id" v-model="actualItem.category_id" :options="rappiCategoriesOptions">
                </vu-select>
              </b-form-group>
            </b-col>
          </b-row>
          <toppings-by-product-id v-if="actualItem.id>0" :menu-id="parseInt(this.$route.params.id)" :product-id="actualItem.id"></toppings-by-product-id>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
import myUpload from "vue-image-crop-upload/upload-2.vue";
import ToppingsByProductId from "./ToppingsByProductId.vue";

export default {
  name: "RappiItems",
  components:{ myUpload, ToppingsByProductId },
  data() {
    return {
      statusoptions: [
        { value: true, text: "ACTIVO" },
        { value: false, text: "INACTIVO" },
      ],
      imgheaders: {
        Authorization: "",
      },
      show:false,
      imgparams: {},
      products: [],
      productOptions: [],
      modifierGroups:[],
      rappiCategoriesOptions:[],
      items: [],
      actualItem: {
        id: 0,
        sku: "",
        name: "",
        description: "",
        type: "PRODUCT",
        price: 0,
        imageUrl: "",
        availableFrom: null,
        availableTo: null,
        rappiIds: null,
        sortingPosition: 0,
        maxLimit: 0,
        category_id: null,
        category: {
          id: 0,
          name: "",
          minQty: 0,
          maxQty: 0,
          sortingPosition: 0
        },
        children: [],
        menu_id: 1,
        product_id: null,
        status: true
      },
      actualcategories:[],
      search: "",
      tableFields: [
        { key: "imageUrl", label: "Imagen" },
        { key: "name", label: "Titulo" },
        { key: "price", label: "Precio" },
        { key: "buttons", label: "" }
      ]
    };
  },
  mounted() {
    this.getAllMenus()
    this.getAllRappiCategories()
    this.getAllProducts()
    this.getProductModifiers()
    this.imgheaders.Authorization =
        "Bearer " + localStorage.getItem("user-token");
  },
  methods: {
    cropSuccess(_, field) {
      console.log("-------- crop success --------");
      console.log(field);
    },

    cropUploadSuccess(jsonData, field) {
      console.log("-------- upload success --------");
      console.log(jsonData);
      console.log("field: " + field);
      this.getItem();
    },

    cropUploadFail(status, field) {
      console.log("-------- upload fail --------");
      console.log(status);
      console.log("field: " + field);

    },
    getAllRappiCategories() {
      let that = this;
      axios
        .get("/rappi/menu/" + this.$route.params.id + "/categories")
        .then(function (response) {
          that.rappiCategories = response.data;
          let persfil = response.data
            .map((pers) => {
              return { id: pers.id, label: pers.name };
            });
          that.rappiCategoriesOptions = persfil;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getModifiergroupNamesFromIds(ids){
      let x= this.modifierGroups.reduce(function(p,x){
        if(ids.includes(x.id)){
          return x.label+(p==""?"":", ")+p
        }else{
          return p
        }
      },"")
      return x
    },
    createUberItem() {
      this.resetactualItem()
      this.$bvModal.show("modal-createUberItem")
    },
    getAllProducts() {
      let that = this;
      axios
        .get("/products")
        .then(function (response) {
          that.products = response.data;
          let persfil = response.data
            .filter((pers) => {
              return pers.status;
            })
            .map((pers) => {
              return { id: pers.id, label: pers.clave + " - " + pers.ndescription };
            });
          let persfils = [{ id: null, label: "NINGUNO" }]
          persfils.push(...persfil)
          that.productOptions = persfils;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getProductModifiers() {
      let that = this;
      axios
        .get("/uber/mastermenu/" + this.$route.params.id + "/modifiergroups" )
        .then(function (response) {
          let x=response.data
          let y=x.map(function(el){
            console.log(el)
            el.label=el.title.translations.en
            return el
          })
          console.log(y)
          that.modifierGroups = y
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    editItem(id) {
      let that = this;
      this.actualItem.id = id
      axios
        .get("/rappi/menu/" + this.$route.params.id + "/item/" + this.actualItem.id)
        .then(function (response) {
          that.actualItem = response.data;
          that.$bvModal.show("modal-createUberItem")
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getAllMenus() {
      let that = this;
      axios
        .get("/rappi/menu/" + this.$route.params.id + "/items")
        .then(function (response) {
          that.items = response.data.filter((pers) => {
              return pers.status;
            });
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    createItem(evt) {
      let that = this;
      let verb = ""
      if (this.actualItem.id > 0) {
        verb = "PUT"
      } else {
        verb = "POST"
      }
      if( !this.actualItem.product_id>0){
        evt.preventDefault()
      }
      console.log(verb)
      axios({ method: verb, url: "/rappi/menu/" + this.$route.params.id + "/item", data: this.actualItem })
        .then(function (response) {
          that.actualItem = response.data;
          that.getAllMenus()
          that.resetactualItem()
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getItem() {
      let that = this;
      axios
        .get("/rappi/menu/" + this.$route.params.id + "/item/" + this.actualItem.id)
        .then(function (response) {
          that.actualItem = response.data;
          if(that.actualItem.external_data.includes("product::")){
            that.actualItem.product_id=that.actualItem.external_data.replace("product::","")
          }
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    resetactualItem() {
      this.actualItem = {
        id: 0,
        sku: "",
        name: "",
        description: "",
        type: "PRODUCT",
        price: 0,
        imageUrl: "",
        availableFrom: null,
        availableTo: null,
        rappiIds: null,
        sortingPosition: 0,
        maxLimit: 0,
        category_id: null,
        category: {
          id: 0,
          name: "",
          minQty: 0,
          maxQty: 0,
          sortingPosition: 0
        },
        children: [],
        menu_id: 1,
        product_id: null,
        status: true
      }
    },
    makeToast(title, content, variant) {
      this.$bvToast.toast(content, {
        title,
        variant,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
  },
};
</script>
  