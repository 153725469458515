<template>
  <div>
    <Nav></Nav>
    <b-container fluid class="mt-3">
      <b-row>
        <b-col>
          <h1>Recetas para manufactura</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-button variant="success" size="sm" @click="$bvModal.show('modal-newBom')">Crear Receta de Manufactura</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Buscar:">
            <b-form-input type="search" v-model="routeQuery.search" @keyup="page = 1"
              debounce="500"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-table ref="selectableTable" selectable @row-selected="onRowSelected" small :filter="routeQuery.search"
            striped hover :items="boms" :fields="bomsLabels" responsive> <!---->
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(active)="data">
              <b-badge :variant="data.item.active ? 'success' : 'danger'">{{ data.item.active ? 'Activo' : 'Inactivo'
                }}</b-badge>
            </template>
            <template #cell(buttons)="data">
              <b-button size="sm" variant="success" @click="$router.push('/bom/' + data.item.id)" class="mr-1">
                +Info
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-modal id="modal-newBom" :hide-footer="true" size="xl" title="Crear Orden de Compra">
      </b-modal>
    </b-container>

  </div>
</template>
<script>
import axios from "axios";
import Nav from "../components/Nav.vue";
export default {
  name: "SuppliesBoms",
  components: {
    Nav
  },

  data() {
    return {
      routeQuery: {
        page: null,
        search: null,
        fini: null,
        ffin: null,
        sucursales: null
      },
      initialDate: "",
      lastDate: "",
      allSucsArray: [],
      search: "",
      boms: [],
      actualSucursal: {
        id: null
      },
      bomsLabels: [
        { key: "id", label: "ID" },
        { key: "name", label: "Nombre" , sortable: true},
        { key: "product_qty", label: "Cantidad", sortable: true },
        { key: "create_date", label: "Creacion", sortable: true },
        { key: "buttons", label: "" }
      ]
    };
  },

  computed: {

  },
  mounted() {
    this.getBoms()
  },
  created() {
  },
  methods: {
    onRowSelected(item) {
      console.log(item)
      if (item.length > 0) {
        this.$router.push('/bom/' + item[0].id)
      }
      this.$refs.selectableTable.clearSelected()
    },
    newBomCreated(evt) {
      console.log(evt)
      this.$router.push('/bom/' + evt)
      this.getBoms()
    },
    getBoms() {
      let that = this
      console.log("/boms")
      axios.get("/boms")
        .then(function (res) {
          that.boms = res.data
        }).catch(function (err) {
          console.error(err)
        })
    },
    makeToast(variant = null, title = "", text = "") {
      if(variant == "success"){
        this.$bvToast.toast(text, {
          title: title,
          variant: variant,
          solid: true,
          appendToast: true,
        });
      } else {
        this.$bvModal.msgBoxOk(text, {
          title: title,
          okVariant: variant,
          centered: true
        })

      }
    },
  },
};
</script>
