<template>
  <div>
    <Nav></Nav>
    <b-container fluid="xl">
      <b-row>
        <b-col>
          <h1> Alta de Insumos</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button variant="default" @click="$router.push('/supply'); getAllSupplies()">Crear Insumo</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Buscar:">
            <b-form-input type="search" v-model="search" @keyup="page = 1" debounce="500"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table small :filter="search" striped hover :items="suppliesTable" :fields="tableFields" responsive
            :per-page="byPage" :current-page="page">
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(buttons)="data">
              <b-button-group vertical>
                <b-button size="sm" variant="warning" @click="$router.push('/supply/' + data.item.id)">Editar</b-button>

              </b-button-group>
            </template>
            <template #cell(status)="data">
              <b-badge pill v-if="data.item.status" variant="success">ACTIVO</b-badge>
              <b-badge pill v-else-if="!data.item.status" variant="danger">INACTIVO</b-badge>
            </template>
            <template #cell(image)="data">
              <img :src="axios.defaults.baseURL + '/' + data.item.image">
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-pagination v-model="page" :total-rows="tableLength" :per-page="byPage"
            aria-controls="my-table"></b-pagination>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import Nav from "../components/Nav.vue";
import axios from "axios";
export default {
  name: "Supplies",
  components: { Nav },
  data() {
    return {
      byPage: 20,
      page: 1,
      suppliesTable: [],
      search: "",
      tableLength: 0,
      subgroups: [],
      groups: [],
      tableFields: [
        { key: "id", label: "ID", sortable: true, sortDirection: "desc" },
        { key: "code", label: "Codigo", sortable: true },
        { key: "name", label: "Nombre" },
        { key: "description", label: "Descripcion", sortable: true },
        { key: "unit", label: "Unidad", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "buttons", sortable: true },
      ],
    };
  },
  mounted() {
    this.getAllSupplies();
  },
  methods: {
    getAllSupplies() {
      let that = this;
      let searchExtra = ""
      if (this.search.length > 0) {
        searchExtra = "?search=" + this.search
      }
      axios
        .get("/supplies" + searchExtra)
        .then(function (response) {
          that.suppliesTable = response.data;
          that.tableLength = response.data.length
        })
        .catch(function (err) {
          console.error(err)
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    makeToast(title, content, variant) {
      this.$bvToast.toast(content, {
        title,
        variant,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
  },
};
</script>
