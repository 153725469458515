<template>
  <div>
    <b-row>
      <b-col>
        <p>Llena los siguientes campos para crear una orden de compra:</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12" md="12">
        <b-form-group label="Sucursal" description="Selecciona la sucursal desde la que se hace la compra">
          <vu-select v-model="newPurchaseOrder.sucursal_id" :options="sucursales" :reduce="(x) => x.id"
            label="nombre"></vu-select>
        </b-form-group>
      </b-col>
      <b-col lg="12" md="12">
        <b-form-group label="Proveedor" description="Selecciona a tu proveedor para dicha compra">
          <vu-select v-model="newPurchaseOrder.provider_id" :options="providers" :reduce="(x) => x.id"
            label="name"></vu-select>
        </b-form-group>
      </b-col>
      <b-col lg="6" md="12">
        <b-form-group label="Nombre de la orden de compra:"
          invalid-feedback="No puedes utilizar los siguientes caracteres / \ < > : “ | ? * "
          :state="nameValidationState"
          description="Nombre de tu orden de compra, por ejemplo OC-A-10may">
          <b-form-input v-model="newPurchaseOrder.name" @input="validateNameInput" type="text" required></b-form-input>
        </b-form-group>
      </b-col>
      <b-col lg="6" md="12">
        <b-form-group label="Entrega Esperada:" description="Fecha en la que esperas recibir la mercancia">
          <b-form-input v-model="newPurchaseOrder.date_planned" type="date" required></b-form-input>
        </b-form-group>
      </b-col>
      <b-col lg="6" md="12">
        <b-form-group label="Notas:" description="Notas de la orden">
          <b-form-input v-model="newPurchaseOrder.notes" type="text" required></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-button variant="success" @click="createPO">Crear Orden</b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import axios from "axios";
export default {
  components: {},
  name: "CreatePurchaseOrder",
  props: ["suc_id"],
  data() {
    return {
      providers: [],
      sucursales:[],
      nameValidationState:false,
      newPurchaseOrder: {
        sucursal_id: null,
        provider_id: null,
        name: "",
        amount_untaxed: 0,
        amount_tax: 0,
        amount_total: 0,
        date_order: null,
        date_planned: "",
        date_approve: null,
        notes:""
      }
    };
  },
  computed: {

  },
  watch: {
  },
  mounted() {
    this.getSucursales()
    this.getProviders()
    this.validateNameInput()
  },
  methods: {
    validateNameInput(){
      let regx = /[/\\<>:“|?*]/g;
      let result = regx.test(this.newPurchaseOrder.name);
      this.nameValidationState=!result
    },
    getSucursales() {
      let that = this
      axios.get("/sucursals")
        .then(function (res) {
          that.sucursales = res.data
        }).catch(function (err) {
          console.error(err)
        })
    },
    getProviders() {
      let that = this
      axios.get("/providers")
        .then(function (res) {
          that.providers = res.data
        }).catch(function (err) {
          console.error(err)
        })
    },
    createPO() {
      let that=this
      axios.post("/purchaseorder",this.newPurchaseOrder)
        .then(function (res) {
          that.$emit("created",res.data.id)
        }).catch(function (err) {
          console.log(err)
          that.$emit("error",err.request)
        })
    }
  }
};
</script>
