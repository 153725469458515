<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col>
          <h2>Items:</h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button size="sm" variant="success" @click="createUberItem">Crear
            Item</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Buscar:">
            <b-form-input type="search" v-model="search" debounce="500"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table small responsive striped hover :filter="search" :items="items" :fields="tableFields">
            <template #cell(buttons)="data">
              <b-button-group vertical>
                <b-button size="sm" variant="default" @click="editItem(data.item.id)">Editar</b-button>
                <b-button size="sm" variant="danger" @click="deleteItem(data.item.id)">Borrar</b-button>
              </b-button-group>
            </template>
            <template #cell(image_url)="data">
              <b-media v-if="data.item.image_url.length > 0" no-body class="align-items-center">
                <b-img class="avatar" rounded="true" height="3em" alt="Image placeholder" :src="data.item.image_url" />
              </b-media>
            </template>
            <template #cell(modifiers)="data">
              {{ getModifiergroupNamesFromIds(data.item.modifier_group_ids.ids) }}
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-container>
    <b-modal id="modal-createUberItem" title="Crear Categoria" size="xl" @ok="createItem">
      <b-row>
        <b-jumbotron class="p-3 mx-auto" v-if="actualItem.type.length > 0">
          <b-row>
            <b-col cols="12" sm="6">
              <b-img fluid v-if="actualItem.image_url != ''" rounded="true" :src="actualItem.image_url" />
            </b-col>
            <b-col class="d-flex align-items-center justify-content-center my-3" cols="12" sm="6">
              <b-button @click="show = !show">Editar Imagen</b-button>
            </b-col>
          </b-row>
        </b-jumbotron>
        <my-upload field="image" @crop-success="cropSuccess" @crop-upload-success="cropUploadSuccess"
          @crop-upload-fail="cropUploadFail" v-model="show" lang="es-MX" lang-type="es-MX" :no-circle="true"
          :width="320" :height="320" :url="axios.defaults.baseURL + '/uber/mastermenu/' + this.$route.params.id + '/item/' + this.actualItem.id + '/image'
            " :params="imgparams" :headers="imgheaders" img-format="png"></my-upload>
      </b-row>
      <b-row>
        <b-col>
          <p>Aqui puedes crear tus productos.</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Nombre:">
            <b-form-input v-model="actualItem.title.translations.en" type="text" required></b-form-input>
          </b-form-group>
        </b-col>
        <!-- <b-col cols="12">
          <b-form-group label="Vender el articulo solo?" >
            <b-form-radio v-model="selected" name="sell-alone" value="">Si</b-form-radio>
            <b-form-radio v-model="selected" name="sell-alone" value="">No</b-form-radio>
          </b-form-group>
        </b-col> -->
        <b-col cols="12">
          <b-form-group label="Descripcion:">
            <b-form-input v-model="actualItem.description.translations.en" type="text" required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Precio:">
            <b-form-input v-model.number="actualItem.price_info.price" type="number" required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col xl="12" md="12" sm="12">
          <b-form-group label="Producto">
            <vu-select :reduce="(x) => x.id" v-model="actualItem.product_id" :options="productOptions"></vu-select>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Grupos de Modificadores">
            <vu-select multiple :reduce="(x) => x.id" v-model="actualItem.modifier_group_ids.ids"
              :options="modifierGroups"></vu-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
import myUpload from "vue-image-crop-upload/upload-2.vue";

export default {
  name: "UberItems",
  components: { myUpload },
  data() {
    return {
      imgheaders: {
        Authorization: "",
      },
      show: false,
      imgparams: {},
      products: [],
      productOptions: [],
      modifierGroups: [],
      items: [],
      actual_product_id: {},
      actualItem: {
        id: "",
        type: "",
        external_data: "",
        image_url: "",
        product_id: 0,
        title: {
          translations: {
            en: ""
          }
        },
        description: {
          translations: {
            en: ""
          }
        },
        price_info: {
          price: 0
        },
        tax_info: {},
        quantity_info: {
          quantity: {},
          overrides: []
        },
        modifier_group_ids: {
          ids: []
        },
        nutritional_info: {}
      },
      actualcategories: [],
      search: "",
      tableFields: [
        { key: "image_url", label: "Imagen" },
        { key: "title.translations.en", label: "Titulo" },
        { key: "price_info.price", label: "Precio" },
        { key: "modifiers", label: " Grupos de Modificadores" },
        { key: "buttons", label: "" }
      ]
    };
  },
  mounted() {
    this.getAllMenus()
    this.getAllProducts()
    this.getProductModifiers()
    this.imgheaders.Authorization =
      "Bearer " + localStorage.getItem("user-token");
  },
  methods: {
    cropSuccess(_, field) {
      console.log("-------- crop success --------");
      console.log(field);
    },

    cropUploadSuccess(jsonData, field) {
      console.log("-------- upload success --------");
      console.log(jsonData);
      console.log("field: " + field);
      this.getItem();
    },

    cropUploadFail(status, field) {
      console.log("-------- upload fail --------");
      console.log(status);
      console.log("field: " + field);

    },
    getModifiergroupNamesFromIds(ids) {
      let x = this.modifierGroups.reduce(function (p, x) {
        if (ids.includes(x.id)) {
          return x.label + (p == "" ? "" : ", ") + p
        } else {
          return p
        }
      }, "")
      return x
    },
    createUberItem() {
      this.resetactualItem()
      this.$bvModal.show("modal-createUberItem")
    },
    getAllProducts() {
      let that = this;
      axios
        .get("/products")
        .then(function (response) {
          that.products = response.data;
          let persfil = response.data
            .filter((pers) => {
              return pers.status;
            })
            .map((pers) => {
              return { id: pers.id, label: pers.clave + " - " + pers.ndescription };
            });
          let persfils = [{ id: null, label: "NINGUNO" }]
          persfils.push(...persfil)
          that.productOptions = persfils;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getProductModifiers() {
      let that = this;
      axios
        .get("/uber/mastermenu/" + this.$route.params.id + "/modifiergroups")
        .then(function (response) {
          let x = response.data
          let y = x.map(function (el) {
            console.log(el)
            el.label = el.title.translations.en
            return el
          })
          console.log(y)
          that.modifierGroups = y
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    editItem(id) {
      let that = this;
      this.actualItem.id = id
      axios
        .get("/uber/mastermenu/" + this.$route.params.id + "/item/" + this.actualItem.id)
        .then(function (response) {
          console.log(response.data.external_data)
          console.log(response.data.external_data.includes("product::"))
          if (response.data.external_data.length > 0) {
            if (response.data.external_data.includes("product::")) {
              console.log(parseInt(response.data.external_data.replace("product::", "")))
              response.data.product_id = parseInt(response.data.external_data.replace("product::", ""))
            }

          }
          console.log(response.data)
          that.actualItem = response.data;

          that.$bvModal.show("modal-createUberItem")
        })
        .catch(function (err) {
          console.log(err)
          that.makeToast("Error", err.request, "danger");
        });
    },
    deleteItem(id) {
      let that = this;
      this.actualItem.id = id
      this.$bvModal.msgBoxConfirm("Estas Seguro que deseas eliminar el producto", {
        title: "Seguro?",
        okVariant: "danger",
        centered: true
      }).then(function (deleteornot) {
        if (deleteornot) {
          axios
            .delete("/uber/mastermenu/" + this.$route.params.id + "/item/" + this.actualItem.id)
            .then(function () {
              that.getAllMenus()
            })
            .catch(function (err) {
              that.makeToast("Error", err.request.response, "danger");
            });
        }

      })


    },
    getAllMenus() {
      let that = this;
      axios
        .get("/uber/mastermenu/" + this.$route.params.id + "/items")
        .then(function (response) {
          that.items = response.data;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    createItem(evt) {
      let that = this;
      let verb = ""
      if (this.actualItem.id.length > 0) {
        verb = "PUT"
      } else {
        verb = "POST"
      }
      if (this.actualItem.product_id > 0) {
        this.actualItem.external_data = "product::" + this.actualItem.product_id.toString()
      } else {
        this.makeToast("Error", "Selecciona un producto Valido", "danger")
        this.actualItem.external_data = "product::"
        evt.preventDefault()
        return
      }
      axios({ method: verb, url: "/uber/mastermenu/" + this.$route.params.id + "/item", data: this.actualItem })
        .then(function (response) {
          that.actualItem = response.data;
          that.getAllMenus()
          that.resetactualItem()
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getItem() {
      let that = this;
      console.log("getitem")
      axios
        .get("/uber/mastermenu/" + this.$route.params.id + "/item/" + this.actualItem.id)
        .then(function (response) {
          that.actualItem = response.data;
          console.log(that.actualItem.external_data)
          if (that.actualItem.external_data.includes("product::")) {
            console.log(parseInt(that.actualItem.external_data.replace("product::", "")))
            that.actualItem.product_id = parseInt(that.actualItem.external_data.replace("product::", ""))
          }
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    resetactualItem() {
      this.actualItem = {
        id: "",
        external_data: "",
        product_id: "",
        image_url: "",
        type: "",
        title: {
          translations: {
            en: ""
          }
        },
        description: {
          translations: {
            en: ""
          }
        },
        price_info: {
          price: 0
        },
        tax_info: {},
        quantity_info: {
          quantity: {},
          overrides: []
        },
        modifier_group_ids: {
          ids: []
        },
        nutritional_info: {}
      }
    },
    makeToast(title, content, variant) {
      this.$bvModal.msgBoxOk(content, {
        title: title,
        okVariant: variant,
        centered: true
      })
      // this.$bvToast.toast(content, {
      //   title,
      //   variant,
      //   autoHideDelay: 5000,
      //   appendToast: true,
      // });

    },
  },
};
</script>