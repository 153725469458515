<template>
  <div>
    <Nav></Nav>
    <b-overlay :show="loadingReports" rounded="sm" spinner-type="grow">
      <b-container fluid>
        <b-row>
          <b-col>
            <h1>Ventas por día</h1>
          </b-col>
        </b-row>
        <ReportSearchBar @search="searchRecv"></ReportSearchBar>
        <b-card class="mt-5" v-if="chartdata.length > 0">
          <b-row>
            <b-col>
              <LineChart :show="nsalesChart.show" :datasets="nsalesChart.datasets" :labels="nsalesChart.labels">
              </LineChart>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <line-chart :data="chartdata" />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <download-csv :data="reportTable" class="btn btn-primary"
                :name="'ventasDiariasArea' + dates.initial_date + 'to' + dates.end_date + '.csv'" separator-excel>Descargar
              </download-csv>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-table small striped hover :items="reportTable" :fields="tableFields" responsive>
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
                <template #cell(buttons)="data">
                  <b-button-group vertical>
                    <b-button variant="warning" :href="'/product/' + data.item.id">Editar</b-button>
                    <b-button v-if="data.item.status == 'ACTIVE'" variant="danger">Desactivar</b-button>
                    <b-button v-else variant="success">Activar</b-button>
                  </b-button-group>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-card>
      </b-container>
    </b-overlay>
  </div>
</template>
<script>
import Nav from "../../components/Nav.vue";
import axios from "axios";
import moment from "moment"
import ReportSearchBar from "./searchBar/ReportSearchBar.vue"

export default {
  name: "ReportDailySells",
  components: { Nav, ReportSearchBar },
  data() {
    return {
      dateToLock: "",
      loadingReports: false,
      nsalesChart: {
        show: true,
        datasets: [{
          label: 'Ventas Por Sucursal',
          data: [],
          fill: false,
          borderColor: '#172b4d',
          backgroundColor: '#172b4d',
          borderWidth: 5
        }],
        labels: []
      },
      chartdata: [
        // { name: "Workout", data: { "2017-01-01": 3, "2017-01-02": 4 } },
        // { name: "Call parents", data: { "2017-01-01": 5, "2017-01-02": 3 } },
      ],
      reportTable: [],
      sucursales: [],
      dates: {
        initial_date: "",
        end_date: "",
        sucursales: [],
      },
      sucOptions: [],
      tableFields: [
        // { key: "id", label: "ID", sortable: true, sortDirection: "desc" },
        // { key: "ndescription", label: "Nombre", sortable: true },
        // { key: "group", label: "Grupo", sortable: true },
        // { key: "subgroup", label: "Subgrupo", sortable: true },
        // { key: "clave", label: "Clave", sortable: true },
        // { key: "short_name", label: "Nombre Cto", sortable: true },
        // { key: "status", label: "Status", sortable: true },
        // { key: "buttons", sortable: true },
      ],
    };
  },
  mounted() {
    this.getSucursals();
  },
  methods: {
    searchRecv(p) {
      this.dates.end_date = moment(p.endDate).format("YYYY-MM-DD")
      this.dates.initial_date = moment(p.initDate).format("YYYY-MM-DD")
      this.dates.sucursales = p.sucursales
      this.getReport()
    },
    getReport() {
      console.log("reports")
      this.loadingReports = true
      let that = this;
      this.reportTable = [];
      axios
        .post("/report/dates", this.dates)
        .then(function (response) {
          let tableHeads = [
            { key: "date", label: "Fecha", sortable: true, stickyColumn: true },
          ];
          that.dates.sucursales.forEach((element) => {
            let name = "";
            that.sucOptions.forEach((sucopt) => {
              if (sucopt.value == element) {
                name = sucopt.text;
              }
            });
            tableHeads.push({
              key: name,
              label: name,
              sortable: true,
            });
          });
          that.tableFields = tableHeads;
          // that.reportTable = response.data;
          let repTab = [];
          repTab = response.data;
          console.log(repTab);
          let arrayDays = that.getDates(
            new Date(that.dates.initial_date),
            new Date(that.dates.end_date)
          );
          console.log(arrayDays);
          arrayDays.forEach((date) => {
            let elmdate = { date: date.toISOString().split("T")[0] };
            repTab.forEach((element) => {
              if (element.date == date.toISOString().split("T")[0]) {
                let suc = that.sucOptions.find(e => e.value == element.sucursal_id)
                elmdate[suc.text] = element.total;
              }
            });
            that.reportTable.push(elmdate);
          });
          that.chartdata = [];
          that.dates.sucursales.forEach((sucursal) => {
            let name = "";
            that.sucOptions.forEach((sucopt) => {
              if (sucopt.value == sucursal) {
                name = sucopt.text;
              }
            });
            let addsuc = { name: name, data: {} };
            repTab.forEach((element) => {
              if (element.sucursal_id == sucursal) {
                addsuc.data[element.date] = element.total;
              }
            });
            that.chartdata.push(addsuc);
          });
        })
        .catch(function (err) {
          console.log(err);
          that.makeToast("Error", err, "danger");
        }).then(() => {
          that.loadingReports = false
        })
    },
    getSucursals() {
      let that = this;
      axios
        .get("/sucursals")
        .then(function (response) {
          that.sucursales = response.data
          response.data.forEach((element) => {
            that.sucOptions.push({ text: element.nombre, value: element.id });
            that.dates.sucursales.push(element.id)
          });
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        })
    },
    getDates(startDate, endDate) {
      const dates = [];
      let currentDate = startDate;
      const addDays = function (days) {
        const date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      };
      while (currentDate <= endDate) {
        dates.push(currentDate);
        currentDate = addDays.call(currentDate, 1);
      }
      return dates;
    },
    makeToast(title, content, variant) {
      this.$bvToast.toast(content, {
        title,
        variant,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
  },
};
</script>
