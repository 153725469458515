<template>
  <div>
      <Nav></Nav>
      <b-container fluid="xl">
          <b-row>
              <b-col>
                  <h1> Métodos de pago </h1>
              </b-col>
          </b-row>
          <b-row>
              <b-col>
                  <b-button variant="default" @click="$router.push('/paymentmethod')"> Crear método de pago </b-button>
              </b-col>
          </b-row>
          <b-row>
              <b-col>
                  <b-form-group label="Buscar: "> 
                      <b-form-input
                      type="search"
                      v-model="search"
                      @keyup="page= 1"
                      debounce="500">
                      </b-form-input>
                  </b-form-group>
              </b-col>
          </b-row>

          <b-row>
              <b-col>
                  <b-table small
                  :filter="search"
                  striped
                  hover
                  :items="paymentmethodsTable"
                  :fields="tableFields"
                  responsive
                  :per-page="byPage"
                  :current-page="page">
                      <template #table-busy>
                          <div class="text-center text-danger my-2">
                              <b-spinner class="align-middle"> </b-spinner>
                              <strong>Loading ... </strong>
                          </div>
                      </template>
                      <template #cell(image)="data"> 
                        <img :src="axios.defaults.baseURL + '/' +data.item.image">
                      </template>
                      <template #cell(accept_tip)="data">
                          <b-badge pill v-if="data.item.accept_tip" variant="success">ACTIVO</b-badge>
                          <b-badge pill v-else-if="!data.item.accept_tip" variant="danger">INACTIVO</b-badge>
                      </template >
                      <template #cell(visible)="data">
                        <b-badge pill v-if="data.item.visible" variant="success">ACTIVO</b-badge>
                        <b-badge pill v-else-if="!data.item.visible" variant="danger">INACTIVO</b-badge>
                      </template>
                      <template #cell(buttons)="data">
                            <b-button-group vertical>
                             <b-button variant="warning" @click="$router.push('/paymentmethod/' + data.item.id)" >Editar</b-button>
                            </b-button-group>
                        </template>
                  </b-table>
              </b-col>
          </b-row>
          <b-row>
              <b-col>
                  <b-pagination
                  v-model="page"
                  :total-rows="paymentmethodsTable.length"
                  :per-page="byPage"
                  aria-controls="my-table">
                  </b-pagination>
              </b-col>
          </b-row>
      </b-container>
  </div>
</template>



<script>
import Nav from "../components/Nav.vue";
import axios from "axios";
export default {
name: "paymentmethods",
components: { Nav },
data() {
  return {
    byPage:20,
    page:1,
    search:"",
    paymentmethodsTable:[],
    tableFields: [
      { key: "id", label: "ID", sortable: true, sortDirection: "desc" },
      { key: "name", label: "Nombre", sortable: true },
      { key: "short_name", label: "Nombre Corto"},
      { key: "image", label: "img" },
      { key: "comision", label: " Comisión"},
      { key: "visible" },
      { key: "accept_tip" },
      { key: "factura_forma", label:"Forma de Factura" },
      { key: "buttons", sortable: true }
    ],
  };
},
mounted(){
  this.getPaymentMethods();
},
methods:{
  getPaymentMethods(){
      let that=this;
      axios
      .get("/paymentmethods")
      .then(function (response) {
        that.paymentmethodsTable = response.data;
      })
      .catch(function (err) {
        that.makeToast("Error", err.request.response, "danger");
      });
  }
},
makeToast(title, content, variant) {
    this.$bvToast.toast(content, {
      title,
      variant,
      autoHideDelay: 5000,
      appendToast: true,
    });
  },

};

</script>



