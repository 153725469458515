<template>
  <div>
    <Nav></Nav>
    <b-container fluid>
      <b-row>
        <b-col class="text-left">
          <b>Lista de compra ID: {{ lista_actual.id }}</b>
          <h1>{{ lista_actual.name }}</h1>
        </b-col>
      </b-row>
      <b-card class="my-2 p-1">
        <b-row>

          <b-col lg="6" md="12">
            <p>
              Nombre: {{ this.lista_actual.name }}<br>
              Descripcion: {{ this.lista_actual.description }}
            </p>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <table class="table text-left table-sm table-responsive">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Producto</th>
                  <th>Unidad</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in actual_list_supplies" v-bind:key="item.id">
                  <td>{{ item.code }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.unit }}</td>
                  <td>
                    <b-button @click="deleteCompraItems(item.id)" size="sm" variant="danger" class="mr-1 mb-1">
                      <b-icon-trash-fill></b-icon-trash-fill>
                    </b-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row class="text-left my-2">
          <b-col>
            <b-button @click="$bvModal.show('modal-newProduct')" size="sm mr-3">Agregar Producto +</b-button>
          </b-col>
        </b-row>

      </b-card>
    </b-container>
    <b-modal id="modal-newProduct" @close="emptyNewItem" :hide-footer="true" size="xl" title="Agregar Producto">
      <b-row>
        <b-col lg="12" cols="12">
          <b-form-group label="Producto" description="Selecciona el producto a agregar">
            <vu-select v-model.number="newItem.supply_id" :options="supplies" :reduce="(x) => x.id"
             ></vu-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="my-3">
          <b-button class="mr-3" @click="createNewItem" variant="success">Guardar</b-button>
          <b-button class="mr-3" @click="$bvModal.hide('modal-newProduct'); emptyNewItem();"
            variant="danger">Cancelar</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<style>
.table-borderless>tbody>tr>td,
.table-borderless>tbody>tr>th,
.table-borderless>tfoot>tr>td,
.table-borderless>tfoot>tr>th,
.table-borderless>thead>tr>td,
.table-borderless>thead>tr>th {
  border: none;

}
</style>
<script>
import axios from "axios";
import Nav from "../components/Nav.vue";
export default {
  name: "SupplyList",
  components: {
    Nav
  },

  data() {
    return {
      supplies: [],
      lista_actual: {
        id: null,
        name: "",
        description: "",
      },
      actual_list_supplies: [],
      newItem: {
        supply_id: null
      }
    };
  },

  computed: {

  },
  watch: {},
  mounted() {
    if (this.$route.params.id > 0) {
      this.lista_actual.id = this.$route.params.id;
      this.getSupplies()
      this.getList()
      this.getListItems()
      // this.emptyNewItem()
      // this.getShoppingLists()
    }
  },
  created() {

  },
  methods: {
    emptyNewItem() {
      this.newItem = {
        supply_id: null
      }
    },
    getSupplies() {
      let that = this
      axios.get("/supplies")
        .then(function (res) {
          let supplies=res.data
          supplies.map(function(r){
            r.label=r.code+' - '+r.name+' ('+r.unit+')'
          })
          that.supplies=supplies
        }).catch(function (err) {
          that.makeToast("danger", "Error", err.response.data)
        })
    },
    getList() {
      let that = this
      axios.get("/shoppinglist/" + this.lista_actual.id)
        .then(function (res) {
          that.lista_actual = res.data
        }).catch(function (err) {
          that.makeToast("danger", "Error", err.response.data)
        })
    },
    getListItems() {
      let that = this
      axios.get("/shoppinglist/" + this.lista_actual.id + "/supplies")
        .then(function (res) {
          that.actual_list_supplies = res.data
        }).catch(function (err) {
          that.makeToast("danger", "Error", err.response.data)
        })
    },
    deleteCompraItems(id) {
      let that = this
      axios.delete("/shoppinglist/" + this.lista_actual.id + "/item/" + id)
        .then(function () {
          that.getListItems()
        }).catch(function (err) {
          that.makeToast("danger", "Error", err.response.data)
        })
    },
    createNewItem() {
      let that = this
      let elm = this.newItem
      axios.post("/shoppinglist/" + this.lista_actual.id + "/item", elm)
        .then(function (res) {
          if (res.data.id > 0) {
            that.getListItems()
            that.emptyNewItem()
            that.$bvModal.hide("modal-newProduct")
          }
        }).catch(function (err) {
          that.makeToast("danger", "Error", err.response.data)
        })

    },
    makeToast(variant = null, title = "", text = "") {
      this.$bvToast.toast(text, {
        title: title,
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
  },
};
</script>
