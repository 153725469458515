<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col>
          <h2>Menus:</h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button size="sm" variant="success" v-b-modal.modal-createUberMenu>Crear
            Menu</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table small responsive striped hover :items="menus" :fields="tableFields">
            <template #cell(buttons)="data">
              <b-button-group vertical>
                <b-button size="sm" variant="warning" @click="editMenu(data.item.id)">Editar</b-button>
              </b-button-group>
            </template>
            <template #cell(categories)="data">
              {{ getCategoryNamesFromIds(data.item.category_ids) }}
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-container>
    <b-modal id="modal-createUberMenu" size="xl" title="Crear Menu" @ok="createMenu">
      <b-container fluid>
        <b-row>
          <b-col>
            <p>Aqui puedes crear el menu y sus horarios.</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Nombre:">
              <b-form-input v-model="actualMenu.title.translations.en" type="text" required></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Categorias">
              <vu-select multiple :reduce="(x) => x.id" v-model="actualMenu.category_ids" :options="categories"></vu-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-for="(menu_day, indexDay) in actualMenu.service_availability" :key="indexDay">
          <b-col cols="12">
            <b-row>
              <b-col>
                {{ menu_day.day_of_week }} <b-button size="sm" variant="success"
                  @click="addTimeToDay(indexDay)">Agregar</b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4" md="12" v-for="(menu_time, index) in menu_day.time_periods" :key="index">
                <b-card>
                  <b-row>
                    <b-col cols="12">
                      <b-form-group label="Hora Inicial:">
                        <vu-select l v-model="actualMenu.service_availability[indexDay].time_periods[index].start_time"
                          :options="arrayTimes"></vu-select>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group label="Hora Final:">
                        <vu-select v-model="actualMenu.service_availability[indexDay].time_periods[index].end_time"
                          :options="arrayTimes"></vu-select>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-button size="sm" variant="danger" @click="quitTimeToDay(indexDay, index)">Borrar</b-button>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>

    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "UberMenus",
  data() {
    return {
      byPage: 20,
      page: 1,
      menus: [],
      categories: [],
      actualMenu: {
        id: "",
        title: {
          translations: {
            en: ""
          }
        },
        service_availability: [
          {
            day_of_week: "monday",
            es_day: "lunes",
            time_periods: [
            ]
          }, {
            day_of_week: "tuesday",
            es_day: "martes",
            time_periods: [
            ]
          }, {
            day_of_week: "wednesday",
            es_day: "miercoles",
            time_periods: [
            ]
          }, {
            day_of_week: "thursday",
            es_day: "jueves",
            time_periods: [
            ]
          }, {
            day_of_week: "friday",
            es_day: "viernes",
            time_periods: [
            ]
          }, {
            day_of_week: "saturday",
            es_day: "sabado",
            time_periods: [
            ]
          }, {
            day_of_week: "sunday",
            es_day: "domingo",
            time_periods: [
            ]
          },
        ],
        category_ids: [],
        invisible: false
      },
      search: "",
      tableFields: [
        { key: "title.translations.en", label: "Titulo" },
        { key: "categories", label: "Categorias" },
        { key: "buttons", label: "" }
      ],
      arrayTimes: [
        "00:00", "00:30",
        "01:00", "01:30",
        "02:00", "02:30",
        "03:00", "03:30",
        "04:00", "04:30",
        "05:00", "05:30",
        "06:00", "06:30",
        "07:00", "07:30",
        "08:00", "08:30",
        "09:00", "09:30",
        "10:00", "10:30",
        "11:00", "11:30",
        "12:00", "12:30",
        "13:00", "13:30",
        "14:00", "14:30",
        "15:00", "15:30",
        "16:00", "16:30",
        "17:00", "17:30",
        "18:00", "18:30",
        "19:00", "19:30",
        "20:00", "20:30",
        "21:00", "21:30",
        "22:00", "22:30",
        "23:00", "23:30",
        "23:59",
      ]
    };
  },
  mounted() {
    this.getAllMenus();
    this.getAllCategories()
  },
  methods: {
    editMenu(id) {
      let that = this;
      this.actualMenu.id = id
      axios
        .get("/uber/mastermenu/" + this.$route.params.id + "/menu/" + this.actualMenu.id)
        .then(function (response) {
          that.actualMenu = response.data;
          that.$bvModal.show("modal-createUberMenu")
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getCategoryNamesFromIds(ids) {
      let x = this.categories.reduce(function (p, x) {
        if (ids.includes(x.id)) {

          return x.label + (p == "" ? "" : ", ") + p
        } else {
          return p
        }
      }, "")
      return x
    },
    getAllMenus() {
      let that = this;
      axios
        .get("/uber/mastermenu/" + this.$route.params.id + "/menus")
        .then(function (response) {
          that.menus = response.data;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getAllCategories() {
      let that = this;
      axios
        .get("/uber/mastermenu/" + this.$route.params.id + "/categories")
        .then(function (response) {
          console.log(response.data)
          let x = response.data
          let y = x.map(function (el) {
            console.log(el)
            el.label = el.title.translations.en
            return el
          })
          console.log(y)
          that.categories = y
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    createMenu() {
      let that = this;
      let verb = ""
      if (this.actualMenu.id.length > 0) {
        verb = "PUT"
      } else {
        verb = "POST"
      }
      console.log(verb)
      axios({ method: verb, url: "/uber/mastermenu/" + this.$route.params.id + "/menu", data: this.actualMenu })
        .then(function (response) {
          that.actualMenu = response.data;
          that.getAllMenus()
          that.resetActualMenu()
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getMenu() {
      let that = this;
      axios
        .get("/uber/mastermenu/" + this.$route.params.id + "/menu/" + this.actualMenu.id)
        .then(function (response) {
          that.actualMenu = response.data;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    resetActualMenu() {
      this.actualMenu = {
        id: "",
        title: {
          translations: {
            en: ""
          }
        },
        service_availability: [
          {
            day_of_week: "monday",
            time_periods: [
            ]
          }, {
            day_of_week: "tuesday",
            time_periods: [
            ]
          }, {
            day_of_week: "wednesday",
            time_periods: [
            ]
          }, {
            day_of_week: "thursday",
            time_periods: [
            ]
          }, {
            day_of_week: "friday",
            time_periods: [
            ]
          }, {
            day_of_week: "saturday",
            time_periods: [
            ]
          }, {
            day_of_week: "sunday",
            time_periods: [
            ]
          },
        ],
        category_ids: [],
        invisible: false
      }
    },
    addTimeToDay(idx) {
      console.log("addtimeto " + idx)
      let times = this.actualMenu.service_availability[idx].time_periods
      times.push({ start_time: "", end_time: "" })
      this.actualMenu.service_availability[idx].time_periods = times
    },
    quitTimeToDay(didx, idx) {
      let myarr = this.actualMenu.service_availability[didx].time_periods
      this.actualMenu.service_availability[didx].time_periods = myarr.filter((x, id) => { return idx != id })
    },
    makeToast(title, content, variant) {
      this.$bvToast.toast(content, {
        title,
        variant,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
  },
};
</script>
  