<template>
  <div>
    <Nav></Nav>
    <b-container fluid class="mt-3">
      <b-row>
        <b-col>
          <h1>Existencias</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button variant="success" size="sm" @click="getexistencias">Actualizar</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Buscar:">
            <b-form-input type="search" v-model="search" @keyup="page = 1" debounce="500"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table small striped hover :filter="search" :items="existencias" :fields="existenciasLabels" responsive> <!--:fields="existenciasLabels"-->
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-modal id="modal-newPurchaseOrder" :hide-footer="true" size="xl" title="Crear Orden de Compra">
      </b-modal>
    </b-container>

  </div>
</template>
<script>
import axios from "axios";
import Nav from "../components/Nav.vue";
export default {
  name: "Existencias",
  components: {
    Nav
  },

  data() {
    return {
      search: "",
      existencias: [],
      existenciasLabels: [
        { key: "location.complete_name", label: "Ubicacion" },
        { key: "supply.id", label: "ID" , sortable: true },
        { key: "supply.code", label: "Codigo" , sortable: true },
        { key: "supply.name", label: "Nombre" , sortable: true },
        { key: "quantity", label: "Inventario" , sortable: true },
        { key: "reserved_quantity", label: "Inventario Reservado" , sortable: true },
      ]
    };
  },

  computed: {

  },
  watch: {},
  mounted() {
    this.getexistencias()
  },
  created() {

  },
  methods: {
    getexistencias() {
      let that = this
      console.log("/stock/quantities")
      axios.get("/stock/quantities")
        .then(function (res) {
          that.existencias = res.data.filter(x=>x.quantity>0 || x.reserved_quantity>0)
        }).catch(function (err) {
          console.error(err)
        })
    },
    makeToast(variant = null, title = "", text = "") {
      this.$bvModal.msgBoxOk(text, {
        title: title,
        okVariant: variant,
        centered: true
      })
      // this.$bvToast.toast(text, {
      //   title: title,
      //   variant: variant,
      //   solid: true,
      //   appendToast: true,
      // });
    },
  },
};
</script>
