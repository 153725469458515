<template>
  <div>
    <Nav></Nav>
    <b-container>
      <b-row>
        <b-col>
          <h1 v-if="actualGroup.id > 0">
            Grupo de Permisos
            {{ this.actualGroup.id + " " + this.actualGroup.name }}
          </h1>
          <h1 v-else>Crear Grupo de Usuarios</h1>
        </b-col>
      </b-row>
      <b-card>
        <b-form>
          <b-row>
            <b-col md="3" sm="12">
              <b-form-group label="Nombre:">
                <b-form-input v-model="actualGroup.name" type="text" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col xl="9" md="12">
              <b-form-group label="Descripcion:">
                <b-form-input v-model="actualGroup.description" type="text" required></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-3">
            <b-button v-if="actualGroup.id > 0" block variant="success" @click="updateProduct">Guardar</b-button>
            <b-button v-else block variant="success" @click="updateProduct">Crear</b-button>
          </b-row>
        </b-form>
      </b-card>
      <b-card>
        <b-row>
          <b-col>
            <h3>Acciones</h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div v-for="(env, env_name) in actionsSelect.options" v-bind:key="env_name">
              <h1>{{ env_name }}</h1>
              <div class="px-3" v-for="(group, group_name) in env" v-bind:key="group_name">
                <h3>{{ group_name }}</h3>
                <div class="px-3">
                  <b-form-checkbox v-for="action in group" v-bind:key="action.id" v-model="actionsSelect.selected" name="checkbox-avtions"
                  :value="action.id" @change="addDeleteGroupAction(action.id)">
                  {{ action.name }}
                </b-form-checkbox>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card>
      <b-card>
        <b-row>
          <b-col>
            <h3>Usuarios</h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-table small :filter="groupUsersTable.filterSearch" striped hover :items="groupUsers"
              :fields="groupUsersTable.fields" responsive :per-page="groupUsersTable.perPage"
              :current-page="groupUsersTable.page">
            </b-table>
          </b-col>
        </b-row>
      </b-card>
    </b-container>
  </div>
</template>
<script>
import Nav from "../components/Nav.vue";
import axios from "axios";
import _ from 'lodash';

export default {
  name: "UserGroup",
  components: { Nav },
  data() {
    return {
      statusoptions: [
        { value: "ACTIVE", text: "ACTIVO" },
        { value: "INACTIVE", text: "INACTIVO" },
      ],
      show: false,
      actualGroup: {
        id: 0,
        description: "",
        code: "",
        name: "",
        unit: "",
        base_portion: 1
      },
      groupUsers: [],
      groupUsersTable: {
        filterSearch: "",
        fields: [],
        perPage: 10,
        page: 1
      },
      groupActions: [],
      groupActionsTable: {
        filterSearch: "",
        fields: [],
        perPage: 10,
        page: 1
      },
      actions: [],
      actionsSelect: {
        selected: [],
        options: []
      }
    };
  },
  beforeRouteUpdate(to, from) {
    console.log(to);
    console.log(from);
    this.initData();
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      console.log("creando");
      console.log(this.$route.params.id);
      if (this.$route.params.id > 0) {
        console.log("producto no nuevo");
        this.actualGroup.id = this.$route.params.id;
        this.getGroup();
        this.getActions()
        this.getGroupActions()
        this.getGroupUsers()
      }
      console.log("termina creacion");
    },
    getGroup() {
      let that = this;
      axios
        .get("/permission/group/" + this.actualGroup.id)
        .then(function (response) {
          that.actualGroup = response.data;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getActions() {
      let that = this;
      axios
        .get("/permission/actions")
        .then(function (response) {
          that.actions = response.data;
          console.log("OPTIONS ACTIONS")
          let l = _(that.actions).groupBy('system_belong').value()
          _.forEach(l, function (val, key) {
            let t = _(val).groupBy("module").value()
            l[key] = t
          })
          that.actionsSelect.options = l
          console.log("END OPTIONS ACTIONS")
        })
        .catch(function (err) {
          console.error(err)
          that.makeToast("Error", err.request, "danger");
        });
    },
    addDeleteGroupAction(selected){
      let that=this
      let ing=that.actionsSelect.selected.includes(selected)
      let action=ing?"POST":"DELETE"
      axios({ method: action, url: "/permission/group/"+that.actualGroup.id+"/actions", data: {group_id:that.actualGroup.id,action_id:selected}})
        .then(function (response) {
          console.log(response.data)
          that.getGroupActions()
          that.makeToast("EXITO", "Permisos insertados/eliminados correctamente", "success");

        })
        .catch(function (err) {
          that.getGroupActions()
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getGroupActions() {
      let that = this;
      axios
        .get("/permission/group/" + this.actualGroup.id + "/actions")
        .then(function (response) {
          that.groupActions = response.data;
          that.actionsSelect.selected=that.groupActions.map(o=>o.id)
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getGroupUsers() {
      let that = this;
      axios
        .get("/permission/group/" + this.actualGroup.id + "/users")
        .then(function (response) {
          that.groupUsers = response.data;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    updateProduct() {
      let that = this;
      if (this.actualGroup.id > 0) {
        axios
          .put("/permission/groups/" + this.actualGroup.id, this.actualGroup)
          .then(function (response) {
            that.actualGroup = response.data;
            that.makeToast("Exito", "exito al guardar", "success");
            that.getGroup();
          })
          .catch(function (err) {
            that.makeToast("Error", err.request.response, "danger");
          });
      } else {
        axios
          .post("/permission/groups", this.actualGroup)
          .then(function (response) {
            that.makeToast("Exito", "exito al crear, redirigiendo", "success");
            console.log(response.data);
            that.$router.push({
              name: "Supply",
              params: { id: response.data.id },
            });
          })
          .catch(function (err) {
            that.makeToast("Error", err.request.response, "danger");
          });
      }
    },

    makeToast(title, content, variant) {
      this.$bvToast.toast(content, {
        title,
        variant,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
  },
};
</script>
