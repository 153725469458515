
<template>
    <div class="progress-container">
      <b-progress height="5vh" class="progress-bar-custom">
        <b-progress-bar v-for="(step) in steps" :value="100/steps.length" :class="'step '+(step.name==currentStep?'active':'inactive')" v-bind:key="step.name">
          {{step.label}}
        </b-progress-bar>
      </b-progress>
    </div>
  </template>
  <script>
  export default {
      name: 'ProgressSteps',
      props: {
          steps: {
              type: Array,
              required: true
          },
          currentStep: {
              type: String,
              required: true
          }
      }
  }
  </script>
  <style>
  .progress-container {
    width: 100%;
  }
  .progress-bar-custom {
    background-color: #f1f1f1;
  }
  .step {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
  }
  .inactive {
    background-color: #9caec3 !important; /* Grayish */
  }
  .active {
    background-color: #a4ecaf !important; /* Light purple */
    color: #07093e !important; /* Darker purple */
  }
  </style>

<!-- 

<template>
    <div class="progress-steps d-flex align-items-center">
        <template v-for="(step, index) in steps" >
            <div  :class="['step-item', { active: index <= currentStep }]" v-bind:key="step" >
                {{ step }}
            </div>
            <b-progress  v-bind:key="step" v-if="index < steps.length - 1" :value="index < currentStep ? 100 : 0" class="flex-grow-1"
                height="2px"></b-progress>
        </template>
    </div>
</template>
<script>
export default {
    name: 'ProgressSteps',
    props: {
        steps: {
            type: Array,
            required: true
        },
        currentStep: {
            type: Number,
            required: true
        }
    }
}
</script>

<style scoped>
.progress-steps {
    background-color: #f0f0f0;
    border-radius: 20px;
    padding: 5px;
}

.step-item {
    padding: 5px 15px;
    border-radius: 15px;
    font-weight: bold;
    color: #888;
}

.step-item.active {
    background-color: #007bff;
    color: white;
}

.progress {
    background-color: #ddd;
}

.progress-bar {
    background-color: #007bff;
}
</style> -->