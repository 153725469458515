<template>
  <div>
    <Nav></Nav>
    <b-overlay :show="loadingReports" rounded="sm" spinner-type="grow">
    <b-container fluid>
      <b-row>
        <b-col>
          <h1>Ventas por día por area</h1>
        </b-col>
      </b-row>
      <ReportSearchBar @search="searchRecv"></ReportSearchBar>
      <b-card class="mt-5" v-if="chartdata.length > 0">
        <b-row>
          <b-col>
            <line-chart :data="chartdata" />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <download-csv :data="reportTable" class="btn btn-primary"
              :name="'ventasDiariasArea'+dates.initial_date+'to'+dates.end_date+'.csv'" separator-excel>Descargar
            </download-csv>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-table small striped hover :items="reportTable" :fields="tableFields" responsive>
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template #cell(buttons)="data">
                <b-button-group vertical>
                  <b-button variant="warning" :href="'/product/' + data.item.id">Editar</b-button>
                  <b-button v-if="data.item.status == 'ACTIVE'" variant="danger">Desactivar</b-button>
                  <b-button v-else variant="success">Activar</b-button>
                </b-button-group>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-card>
    </b-container>
  </b-overlay>
  </div>
</template>
<script>
import Nav from "../../components/Nav.vue";
import axios from "axios";
import moment from "moment"
import ReportSearchBar from "./searchBar/ReportSearchBar.vue";
export default {
  name: "ReportDailySellsArea",
  components: { Nav , ReportSearchBar},
  data() {
    return {
      dateToLock: "",
      loadingReports: false,

      chartdata: [
        // { name: "Workout", data: { "2017-01-01": 3, "2017-01-02": 4 } },
        // { name: "Call parents", data: { "2017-01-01": 5, "2017-01-02": 3 } },
      ],
      arrDates: [],
      restAreas: [],
      reportTable: [],
      sucursales: [],
      dates: {
        initial_date: "",
        end_date: "",
        sucursales: [],
      },
      sucOptions: [],
      tableFields: [
        // { key: "id", label: "ID", sortable: true, sortDirection: "desc" },
        // { key: "ndescription", label: "Nombre", sortable: true },
        // { key: "group", label: "Grupo", sortable: true },
        // { key: "subgroup", label: "Subgrupo", sortable: true },
        // { key: "clave", label: "Clave", sortable: true },
        // { key: "short_name", label: "Nombre Cto", sortable: true },
        // { key: "status", label: "Status", sortable: true },
        // { key: "buttons", sortable: true },
      ],
    };
  },
  mounted() {
    this.getSucursals();
    this.getRestAreas();
  },
  methods: {
    searchRecv(p){
      this.dates.end_date=moment(p.endDate).format("YYYY-MM-DD")
      this.dates.initial_date=moment(p.initDate).format("YYYY-MM-DD")
      this.dates.sucursales=p.sucursales
      this.getReport()
    },
    selectAllSucursales() {
      let that = this
      this.dates.sucursales = []
      this.sucOptions.forEach((element) => {
        that.dates.sucursales.push(element.value)
      })
    },
    setDateLock() {
      this.dates.end_date = ""
      this.dateToLock = moment(this.dates.initial_date).add(1, 'day').format('YYYY-MM-DD');
    },
    getReport() {
      this.loadingReports = true
      let that = this;
      this.reportTable = [];
      axios
        .post("/report/dates/area", this.dates)
        .then(function (response) {
          let tableHeads = [
            { key: "order", label: "Ord", sortable: true, stickyColumn: true },
            { key: "area", label: "Area", sortable: true, stickyColumn: true },
          ];
          that.arrDates = []
          let fromDate = moment(that.dates.initial_date)
          let toDate = moment(that.dates.end_date)
          while (fromDate <= toDate) {
            let ld = fromDate.format('YYYY-MM-DD')
            that.arrDates.push(ld)
            fromDate = moment(fromDate).add(1, 'days');
          }
          that.arrDates.forEach((element) => {
            tableHeads.push({
              key:  element,
              label: element,
              sortable: true,
            });
          });
          that.tableFields = tableHeads;
          // that.reportTable = response.data;
          let repTab = [];
          repTab = response.data;
          console.log(repTab);

          let orderArray = [3, 4, 5, 6, 2, 1]

          that.restAreas.forEach(restarea => {
            let elmarea = { area: restarea.name };
            that.arrDates.forEach(date => {
              repTab.forEach(report => {
                if (report.restaurant_area_id == restarea.id && date == report.date) {
                  elmarea[ date] = report.total;
                }
              })
            })
            elmarea.order = orderArray.indexOf(restarea.id)
            that.reportTable.push(elmarea);
          })

          that.chartdata = [];




          that.restAreas.forEach((restArea) => {
            let name = restArea.name
            let addsuc = { name: name, data: {} };
            repTab.forEach((element) => {
              if (element.restaurant_area_id == restArea.id) {
                addsuc.data[element.date] = element.total;
              }
            });
            that.chartdata.push(addsuc);
          })
        })
        .catch(function (err) {
          console.log(err);
          that.makeToast("Error", err, "danger");
        }).then(() => {
          that.loadingReports = false
        })
    },
    getSucursals() {
      this.loadingReports = true
      let that = this;
      axios
        .get("/sucursals")
        .then(function (response) {
          that.sucursales = response.data
          response.data.forEach((element) => {
            that.sucOptions.push({ text: element.nombre, value: element.id });
            // that.dates.sucursales.push(element.id)
          });
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        })
        .then(() => {
          that.loadingReports = false
        })
    },
    getRestAreas() {
      this.loadingReports = true
      let that = this;
      axios
        .get("/restaurant/areas")
        .then(function (response) {
          that.restAreas = response.data;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        })
        .then(() => {
          that.loadingReports = false
        })
    },
    getDates(startDate, endDate) {
      const dates = [];
      let currentDate = startDate;
      const addDays = function (days) {
        const date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      };
      while (currentDate <= endDate) {
        dates.push(currentDate);
        currentDate = addDays.call(currentDate, 1);
      }
      return dates;
    },
    makeToast(title, content, variant) {
      this.$bvToast.toast(content, {
        title,
        variant,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
  },
};
</script>
