<template>
  <div>
    <Nav></Nav>
    <b-container fluid="xl">
      <b-row>
        <b-col>
      
          
          <h1>Grupos</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button variant="default" @click="$router.push('/group')">Crear Grupo</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Buscar:">
            <b-form-input
              type="search"
              v-model="search"
              @keyup="page = 1"
              debounce="500"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table
          small
          :filter="search"
            striped
            hover
            :items="groups"
            :fields="tableFields"
            responsive="md"
            :per-page="byPage"
            :current-page="page"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(status)="data">
              <b-badge pill v-if="data.item.status" variant="success">ACTIVO</b-badge>
              <b-badge pill v-else-if="!data.item.status" variant="danger">INACTIVO</b-badge>
            </template>
             <template #cell(products)="data">
             {{data.item.products.length}}
            </template>
            <template #cell(buttons)="data">
              <b-button-group vertical>
                <b-button size="sm" variant="default" @click="$router.push('/group/' + data.item.id)"
                  >Editar</b-button
                >
              </b-button-group>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-pagination
            v-model="page"
            :total-rows="groups.length"
            :per-page="byPage"
            aria-controls="my-table"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import Nav from "../components/Nav.vue";
import axios from "axios";
export default {
  name: "Groups",
  components: { Nav },
  data() {
    return {
      byPage: 20,
      page: 1,

      search: "",
      subgroups: [],
      groups: [],
      tableFields: [
        { key: "id", label: "ID", sortable: true, sortDirection: "desc" },
        { key: "name", label: "Nombre", sortable: true },
        { key: "button_text", label: "Boton", sortable: true },
        { key: "products", label: "Productos", sortable: true },
        
        { key: "ordenation", label: "Orden", sortable: true },
        { key: "status" },
        { key: "buttons" },
      ],
    };
  },
  mounted() {
    this.getProductGroups();
    // this.getProductSubgroups();
  },
  methods: {
    cleanNewUser() {
      this.newuser.name = "";
      this.newuser.password = "";
      this.newuser.email = "test@mail.com";
      this.newuser.username = "";
      this.newuser.cellphone = "5511223344";
    },
    newUser(event) {
      event.preventDefault();
      let that = this;
      axios
        .post("/user", this.newuser)
        .then(function (data) {
          that.makeToast(
            "Exito",
            data.response.username + " creado",
            "success"
          );
          that.cleanNewUser();
          that.getAllUsers();
          that.search = data.response.username;
        })
        .catch(function (err) {
          console.log(err.request.response);
          that.makeToast("Error", err.request.response, "danger");
        });
    },

    searchGroup(id) {
      return (
        this.groups.find((element) => element.id == id) || { name: "error" }
      );
    },
    getProductGroups() {
      let that = this;
      axios
        .get("/groups")
        .then(function (response) {
          that.groups = response.data;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    searchSubgroup(id) {
      return (
        this.subgroups.find((element) => element.id == id) || { name: "error" }
      );
    },
    getProductSubgroups() {
      let that = this;
      axios
        .get("/subgroups")
        .then(function (response) {
          that.subgroups = response.data;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    makeToast(title, content, variant) {
      this.$bvToast.toast(content, {
        title,
        variant,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
  },
};
</script>
<style lang="scss">
</style>
