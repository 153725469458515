<template>
  <div>
    <Nav></Nav>
    <b-container>
      <b-row>
        <b-col>
          <h1 v-if="actualProduct.id > 0">
            Producto
            {{ this.actualProduct.id + " " + this.actualProduct.ndescription }}
          </h1>
          <h1 v-else>Crear Producto</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-jumbotron class="p-3 mx-auto" v-if="actualProduct.id > 0">
          <b-row>
          <b-col cols="12" sm="6">
            <b-img fluid v-if="actualProduct.image!=''" rounded="true"  :src="axios.defaults.baseURL + '/' + this.actualProduct.image" />
          </b-col >
          <b-col cols="12" sm="6">
            <b-button class="m-3" @click="show = !show">Editar Imagen</b-button>
          </b-col>
        </b-row>
        </b-jumbotron>
        <my-upload field="image" @crop-success="cropSuccess" @crop-upload-success="cropUploadSuccess"
          @crop-upload-fail="cropUploadFail" v-model="show" lang="es-MX" lang-type="es-MX" :no-circle="true" :width="128"
          :height="128" :url="axios.defaults.baseURL +
            '/product/' +
            this.actualProduct.id +
            '/image'
            " :params="imgparams" :headers="imgheaders" img-format="png"></my-upload>
      </b-row>
      <b-card>
        <b-form>
          <b-row>
            <b-col md="6" sm="12">
              <b-form-group label="Clave:">
                <b-form-input v-model="actualProduct.clave" type="text" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6" sm="12">
              <b-form-group label="Codigo de barra:">
                <b-form-input v-model="actualProduct.barcode" type="text" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col xl="6" md="12">
              <b-form-group label="Nombre:">
                <b-form-input v-model="actualProduct.ndescription" type="text" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col xl="6" md="12">
              <b-form-group label="Nombre Corto (boton captura tactil):">
                <b-form-input v-model="actualProduct.short_name" type="text" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col xl="4" md="4" sm="12">
              <b-form-group label="Status:">
                <b-form-select v-model="actualProduct.status" :options="statusoptions"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col xl="4" md="4" sm="12">
              <b-form-group label="Grupo:">
                <b-form-select v-model="actualProduct.group" :options="groupOptions"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col xl="4" md="4" sm="12">
              <b-form-group label="Area Preparacion:">
                <b-form-select v-model="actualProduct.prep_area" :options="prepareasOptions"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col xl="4" md="4" sm="12">
              <b-form-group label="Tipo:">
                <b-form-select v-model="actualProduct.meal_type_id" :options="mealtypesOptions"></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-3">
            <b-button  v-if="actualProduct.id > 0" block variant="success" @click="updateProduct">Guardar</b-button>
            <b-button  v-else block variant="success" @click="updateProduct">Crear</b-button>
          </b-row>
        </b-form>
      </b-card>
      <b-row class="mt-3" v-if="actualProduct.id > 0">
        <b-col>
          <b-card title="Personalizaciones">
            <b-button variant="primary" @click="openModalPersonalization()">Agregar personalizacion</b-button>
            <b-table stacked="sm" v-if="actualProduct.product_personalization.length > 0" responsive
              :fields="fieldsPersonalizations" :items="actualProduct.product_personalization">
              <template #cell(default_selected)="data">
                <b-badge pill v-if="data.item.default_selected" variant="success">SI</b-badge>
                <b-badge pill v-else-if="!data.item.default_selected" variant="danger">NO</b-badge>
              </template>
              <template v-slot:cell(edit)="data">
                <b-button size="sm" variant="danger" @click="deleteProductPersonalization(data.item)">Eliminar</b-button>
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mt-3" v-if="actualProduct.id > 0">
        <b-col>
          <b-card title="Precios">
            <b-button variant="primary" @click="openModalPrice({ id: 0 })">Agregar precio</b-button>
            <b-table small stacked="sm" v-if="filteredProductPrices.length > 0" responsive :items="filteredProductPrices"
              :fields="fieldsPrices">
              <template #cell(area_restaurant_id)="data">
                {{
                  data.item.area_restaurant_id +
                  " " +
                  searchRestArea(data.item.area_restaurant_id).name
                }}
              </template>

              <template v-slot:cell(edit_price)="data">
                <b-button size="sm" @click="openModalPrice(data.item)" variant="warning">Editar</b-button>
                <b-button size="sm" variant="danger" @click="deleteProductPrice(data.item)">Eliminar</b-button>

              </template>
            </b-table>
            <b-row>
              <b-col>
                <b-badge pill variant="success">En seguimiento</b-badge>
                <b-badge pill variant="danger">Unico</b-badge>
                <b-badge pill variant="secondary">Deshabilitado</b-badge>
              </b-col>
            </b-row>
            <b-table small stacked="sm" v-if="pivotPrices.length > 0" responsive :items="pivotPrices"
              :fields="fieldsPivotPrices">
              <template v-slot:cell(sucursal_id)="{item}">
                {{searchSucursal(item.sucursal_id).nombre}}
              </template>
              <template v-for="area in restAreas" v-slot:[`cell(${area.id})`]="{ item }">
                  <b-button size="sm" :key="area.id" :variant="(!item['s'+area.id]?'secondary':item[('f'+area.id)]?'success':'danger')"  @click="openModalPriceByPriceArea({area_restaurant_id:area.id,sucursal_id:item.sucursal_id})">{{ (!item['s'+area.id]?'-':item[area.id]) }}</b-button>
              </template>

            </b-table>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mt-3" v-if="actualProduct.id > 0">
        <b-col>
          <b-card title="Receta">
            <b-button variant="primary" @click="openModalRecipe({ id: 0 })">Agregar</b-button>
            <b-table small stacked="sm" responsive v-if="product_recipes.length > 0" :items="product_recipes"
              :fields="fieldsRecipe">
              <template #cell(supply_id)="data">
                {{
                  data.item.supply_id +
                  " " +
                  searchSupply(data.item.supply_id).name
                }}
              </template>
              <template #cell(base_portion)="data">
                {{ searchSupply(data.item.supply_id).base_portion }}
              </template>
              <template #cell(qty)="data">
                {{
                  data.item.qty_portions *
                  searchSupply(data.item.supply_id).base_portion
                }}
              </template>
              <template #cell(unit)="data">
                {{ searchSupply(data.item.supply_id).unit }}
              </template>
              <template v-slot:cell(edit_supply)="data">
                <b-button size="sm" @click="openModalRecipe(data.item)" variant="warning">Editar</b-button>
                <b-button size="sm" variant="danger" @click="deleteProductRecipe(data.item)">Eliminar</b-button>
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <b-modal id="modal-addRecipe" size="xl" title="Receta" @ok="createProductRecipe">
      <b-row>
        <b-col md="6" sm="12">
          <b-form-group label="Ingrediente:">
            <vu-select :reduce="(x) => x.value" v-model="actualRecipe.supply_id" :options="optionSupplies"></vu-select>
            <!-- <b-form-select v-model="actualRecipe.supply_id" :options="optionSupplies"></b-form-select> -->
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group label="Porciones:">
            <b-form-input v-model.number="actualRecipe.qty_portions" type="number" placeholder="Porciones"
              required></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal id="modal-price" title="Precio" @ok="createProductPrice">
      <b-row v-if="(actualPrice.sucursal_id!=null)">
        <b-col>
          <b-form-group label="Master:">
            <b-form-checkbox :disabled="actualPrice.sucursal_id==null" v-model="actualPrice.follow_price">
              Sigue el precio master?
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Status:">
            <b-form-checkbox v-model="actualPrice.status">
              Habilitado
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="!actualPrice.follow_price || (actualPrice.sucursal_id==null)">
        <b-col md="6" sm="12">
          <b-form-group label="Area:">
            <b-form-select v-model="actualPrice.area_restaurant_id" :options="optionRestAreas"></b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Precio:">
            <b-form-input v-model.number="actualPrice.price" type="number" placeholder="Precio" required></b-form-input>
          </b-form-group>
        </b-col>
        
      </b-row>
    </b-modal>
    <b-modal id="modal-addPersonalization" title="Personalizacion" @ok="createProductPersonalization">
      <b-row>
        <b-col md="6" sm="12">
          <b-form-group label="Personalizacion:">
            <b-form-select v-model="actualPersonalization.personalization_id"
              :options="personalizationOptions"></b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group label="Habilitada Default:">
            <b-form-checkbox v-model="actualPersonalization.default_selected">
              Default
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import Nav from "../components/Nav.vue";
import axios from "axios";
import myUpload from "vue-image-crop-upload/upload-2.vue";
export default {
  name: "Products",
  components: { Nav, myUpload },
  data() {
    return {
      statusoptions: [
        { value: "ACTIVE", text: "ACTIVO" },
        { value: "INACTIVE", text: "INACTIVO" },
      ],
      imgheaders: {
        Authorization: "",
      },
      imgparams: {},
      show: false,
      personalizationOptions: [],
      actualPersonalization: {
        id: 0,
        product_id: 0,
        personalization_id: 0,
        default_selected: false,
      },
      actualPrice: {
        id: 0,
        product_id: 0,
        area_restaurant_id: 0,
        price: 0,
        price_wo_tax: 0,
        tax: 0,
        tax_excempt: false,
        billable: true,
      },
      actualRecipe: {
        id: 0,
        product_id: 0,
        supply_id: 0,
        qty_portions: 0,
      },
      actualProduct: {
        id: 0,
        ndescription: "",
        group: 1,
        subgroup: 1,
        clave: "",
        short_name: "",
        waiter_commision: 0,
        barcode: "",
        prep_area: 1,
        image: "",
        image_mini: "",
        status: "ACTIVE",
        meal_type_id:null,
        product_prices: [],
        product_modifiers: [],
        product_comments: [],
        product_personalization: [],
      },
      filteredProductPrices: [],
      pivotPrices:[],
      editProductPrice: {
        area_restaurant_id: 1,
        billable: false,
        id: 0,
        price: 0,
        price_wo_tax: 0,
        product_id: 0,
        tax: 0,
        tax_excempt: false,
      },
      sucursals:[],
      personalizations: [],

      prepareas: [],
      prepareasOptions: [],
      meal_types:[],
      mealtypesOptions:[],
      product_recipes: [],
      groups: [],
      groupOptions: [],
      optionRestAreas: [],
      subgroups: [],
      supplies: [],
      restAreas: [],
      optionSupplies: [],
      fieldsPersonalizations: [
        { key: "id", label: "ID" },
        { key: "personalization_id", label: "P.ID" },
        { key: "personalization.name", label: "Nombre" },
        { key: "default_selected", label: "Default" },
        { key: "edit", label: "" },
      ],
      fieldsPrices: [
        { key: "area_restaurant_id", label: "Area" },
        { key: "price", label: "Precio" },
        { key: "sucursal_id", label: "Sucursal" },
        { key: "edit_price", label: "" },
      ],
      fieldsPivotPrices: [
        { key: "sucursal_id", label: "sucursal" ,sortable: true},
        { key: "1", label: "1" },
        { key: "2", label: "2" },
        { key: "3", label: "3" },
        { key: "4", label: "4" },
        { key: "5", label: "5" },
        { key: "6", label: "6" }
        
      ],
      fieldsRecipe: [
        { key: "supply_id", label: "Ingrediente" },
        { key: "base_portion", label: "Porcion Base" },
        { key: "qty_portions", label: "Cant" },
        { key: "qty", label: "Total" },
        { key: "unit", label: "Unidad" },
        { key: "edit_supply", label: "" },
      ],
    };
  },
  beforeRouteUpdate(to, from) {
    console.log(to);
    console.log(from);
    this.initData();
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      console.log("creando");
      this.imgheaders.Authorization =
        "Bearer " + localStorage.getItem("user-token");
      this.getProductGroups();
      this.getProductSubgroups();
      this.getRestAreas();
      this.getSupplies();
      this.getSucursales();
      this.getAllPersonalizations();
      this.getAllPrepAreas();
      this.getMealTypes()
      console.log(this.$route.params.id);
      if (this.$route.params.id > 0) {
        console.log("producto no nuevo");
        this.actualProduct.id = this.$route.params.id;
        this.actualPersonalization.product_id = parseInt(this.actualProduct.id);
        this.getProduct();
        this.getProductRecipe();
      }
      console.log("termina creacion");
    },

    getMealTypes() {
      let that = this;
      axios
        .get("/mealtypes")
        .then(function (response) {
          that.meal_types = response.data;
          let persfil = response.data
            .map((pers) => {
              return { value: pers.id, text: pers.name };
            }).sort((a, b) => {
              return a.text.localeCompare(b.text);
            });
          that.mealtypesOptions = persfil;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getAllPersonalizations() {
      let that = this;
      axios
        .get("/personalizations")
        .then(function (response) {
          that.personalizations = response.data;
          let persfil = response.data
            .filter((pers) => {
              return pers.status;
            })
            .map((pers) => {
              return { value: pers.id, text: pers.name };
            }).sort((a, b) => {
              return a.text.localeCompare(b.text);
            });
          that.personalizationOptions = persfil;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getAllPrepAreas() {
      let that = this;
      axios
        .get("/preparationareas")
        .then(function (response) {
          that.prepareas = response.data;
          let persfil = response.data
            .filter((pers) => {
              return pers.status;
            })
            .map((pers) => {
              return { value: pers.id, text: pers.name };
            });
          that.prepareasOptions = persfil;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getSucursales() {
      let that = this;
      axios
        .get("/sucursals")
        .then(function (response) {
          that.sucursals = response.data;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    createProductPersonalization() {
      let that = this;
      if (this.actualPersonalization.id > 0) {
        console.log("put productpers");
        axios
          .put(
            "/product/personalization/" + this.actualPersonalization.id,
            this.actualPersonalization
          )
          .then(function (response) {
            that.getAllPersonalizations();
            that.getProduct();
            that.makeToast("Exito", response.data.id, "success");
          })
          .catch(function (err) {
            that.makeToast("Error", err.request.response, "danger");
          });
      } else {
        console.log("post productpers");
        axios
          .post("/product/personalization", this.actualPersonalization)
          .then(function (response) {
            that.getAllPersonalizations();
            that.getProduct();

            that.makeToast("Exito", response.data.id, "success");
          })
          .catch(function (err) {
            that.makeToast("Error", err.request.response, "danger");
          });
      }
    },
    createProductPrice() {
      let that = this;
      if (this.actualPrice.id > 0) {
        console.log("put productprice");
        axios
          .put("/product/price/" + this.actualPrice.id, this.actualPrice)
          .then(function (response) {
            that.getProduct();
            that.makeToast("Exito", response.data.id, "success");
          })
          .catch(function (err) {
            that.makeToast("Error", err.request.response, "danger");
            that.getProduct();
          });
      } else {
        console.log("post productprice");
        axios
          .post("/product/price", this.actualPrice)
          .then(function (response) {
            that.getProduct();
            that.makeToast("Exito", response.data.id, "success");
          })
          .catch(function (err) {
            that.makeToast("Error", err.request.response, "danger");
            that.getProduct();
          });
      }
    },
    defaultProductPrice() {
      this.actualPrice = {
        id: 0,
        product_id: this.actualProduct.id,
        area_restaurant_id: 0,
        price: 0,
        price_wo_tax: 0,
        tax: 0,
        tax_excempt: false,
        billable: true,
      };
    },
    openModalPersonalization(pid) {
      this.$bvModal.show("modal-addPersonalization");
      this.actualPersonalization.id = pid;
    },
    openModalRecipe(pid) {
      this.$bvModal.show("modal-addRecipe");
      if (pid.id == 0) {
        this.defaultProductRecipe();
      } else {
        this.actualRecipe = pid;
      }
    },
    defaultProductRecipe() {
      this.actualRecipe = {
        id: 0,
        product_id: this.actualProduct.id,
        supply_id: 0,
        qty_portions: 0,
      };
    },
    createProductRecipe() {
      let that = this;
      if (this.actualRecipe.id > 0) {
        console.log("put createProductRecipe");
        axios
          .put("/recipe/" + this.actualRecipe.id, this.actualRecipe)
          .then(function (response) {
            that.getProductRecipe();
            that.makeToast("Exito", response.data.id, "success");
          })
          .catch(function (err) {
            that.makeToast("Error", err.request.response, "danger");
            that.getProductRecipe();
          });
      } else {
        console.log("post createProductRecipe");
        axios
          .post("/recipe", this.actualRecipe)
          .then(function (response) {
            that.getProductRecipe();
            that.makeToast("Exito", response.data.id, "success");
          })
          .catch(function (err) {
            that.makeToast("Error", err.request.response, "danger");
            that.getProductRecipe();
          });
      }
    },
    deleteProductRecipe(pid) {
      let that = this
      axios
        .delete("/recipe/" + pid.id, this.actualRecipe)
        .then(function (response) {
          that.getProductRecipe();
          that.makeToast("Exito", response.data.id, "success");
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
          that.getProductRecipe();
        });
    },
    deleteProductPrice(pid) {
      let that = this
      axios
        .delete("/product/price/" + pid.id)
        .then(function (response) {
          that.getProduct();
          that.makeToast("Exito", response.data.id, "success");
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
          that.getProduct();
        });
    },
    deleteProductPersonalization(pid) {
      let that = this
      axios
        .delete("/product/personalization/" + pid.id)
        .then(function (response) {
          that.getProduct();
          that.makeToast("Exito", response.data.id, "success");
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
          that.getProduct();
        });
    },
    openModalPrice(pid) {
      this.$bvModal.show("modal-price");
      if (pid.id == 0) {
        this.defaultProductPrice();
      } else {
        this.actualPrice = this.actualProduct.product_prices.find(x=>x.id==pid.id)
        if(this.actualPrice==undefined){
          this.defaultProductPrice()
        }
      }
    },
    openModalPriceByPriceArea(pid) {
      let r=this.actualProduct.product_prices
      console.log(r)
      console.log("openModalPriceByPriceArea")
      console.log(pid.area_restaurant_id+" - "+pid.sucursal_id)
      console.log(r)
      let t = r.find(function(x){ return x.area_restaurant_id==pid.area_restaurant_id && x.sucursal_id==pid.sucursal_id})
      this.actualPrice=t
      if(this.actualPrice==undefined){
        this.defaultProductPrice()
        this.actualPrice.area_restaurant_id=pid.area_restaurant_id
        this.actualPrice.sucursal_id=pid.sucursal_id
        this.actualPrice.product_id=this.actualProduct.id
      }
      this.$bvModal.show("modal-price");
      console.log("end modal open")
    },
    cropSuccess(_, field) {
      console.log("-------- crop success --------");
      console.log(field);
    },

    cropUploadSuccess(jsonData, field) {
      console.log("-------- upload success --------");
      console.log(jsonData);
      console.log("field: " + field);
      this.getProduct();
    },

    cropUploadFail(status, field) {
      console.log("-------- upload fail --------");
      console.log(status);
      console.log("field: " + field);
    },
    newUser(event) {
      event.preventDefault();
      let that = this;
      axios
        .post("/user", this.newuser)
        .then(function (data) {
          that.makeToast(
            "Exito",
            data.response.username + " creado",
            "success"
          );
          that.cleanNewUser();
          that.getAllUsers();
          that.search = data.response.username;
        })
        .catch(function (err) {
          console.log(err.request.response);
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getProduct() {
      let that = this;
      axios
        .get("/product/" + this.actualProduct.id)
        .then(function (response) {
          console.log(response.data)
          var r=response.data
          that.actualProduct = r
          that.filteredProductPrices = response.data.product_prices.filter(price => price.sucursal_id == null)
        })
        .catch(function (err) {
          console.log(err)
          that.makeToast("Error", err.request.response, "danger");
        });
        axios
        .get("/product/" + this.actualProduct.id)
        .then(function (response) {
          console.log(response.data)
          let w = response.data.product_prices.filter(price => price.sucursal_id != null )
          console.log(w)
          let n=w.reduce((x, y) => {
            y[y.area_restaurant_id] = y.price;
            y["s"+y.area_restaurant_id]=y.status
            y["f"+y.area_restaurant_id]=y.follow_price

            delete y['date'];
            delete y['price'];
            let key = y.sucursal_id
            if (!(key in x)) x[key] = y;
            else x[key] = { ...x[key], ...y };
            return x;
          },[]);
          that.pivotPrices=n
        })
        .catch(function (err) {
          console.log(err)
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getProductRecipe() {
      let that = this;
      axios
        .get("/product/" + this.actualProduct.id + "/recipe")
        .then(function (response) {
          that.product_recipes = response.data;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getProductGroups() {
      let that = this;
      axios
        .get("/groups")
        .then(function (response) {
          that.groups = response.data;
          that.groupOptions = response.data.map(function (act) {
            return { value: act.id, text: act.name };
          });
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getProductSubgroups() {
      let that = this;
      axios
        .get("/subgroups")
        .then(function (response) {
          that.subgroups = response.data;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    searchSupply(id) {
      return (
        this.supplies.find((element) => element.id == id) || { name: "error" }
      );
    },
    getSupplies() {
      let that = this;
      axios
        .get("/supplies")
        .then(function (response) {
          that.supplies = response.data;
          that.optionSupplies = response.data
            .sort(function (a, b) {
              return a.name.localeCompare(b.name);
            })
            .map((x) => {
              return {
                value: x.id,
                label: x.name + " (" + x.base_portion + " " + x.unit + ")",
              };
            });
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    searchRestArea(id) {
      return (
        this.restAreas.find((element) => element.id == id) || { name: "error" }
      );
    },
    searchSucursal(id) {
      return (
        this.sucursals.find((element) => element.id == id) || { nombre: "error" }
      );
    },
    getRestAreas() {
      let that = this;
      axios
        .get("/restaurant/areas")
        .then(function (response) {
          that.restAreas = response.data;
          that.optionRestAreas = response.data.map((x) => {
            return { value: x.id, text: x.name };
          });
          let s=[{ key: "sucursal_id", label: "sucursal", sortable:true},]
          response.data.map((x) => {
            s.push({ key: x.id.toString(), label: x.name })
            return { key: x.id, label: x.name };
          });
          that.fieldsPivotPrices=s

        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    updateProduct() {
      let that = this;
      if (this.actualProduct.id > 0) {
        axios
          .put("/product/" + this.actualProduct.id, this.actualProduct)
          .then(function (response) {
            that.actualProduct = response.data;
            that.makeToast("Exito", "exito al guardar", "success");
            that.getProduct();
          })
          .catch(function (err) {
            that.makeToast("Error", err.request.response, "danger");
          });
      } else {
        axios
          .post("/product", this.actualProduct)
          .then(function (response) {
            that.makeToast("Exito", "exito al crear, redirigiendo", "success");
            console.log(response.data);
            that.$router.push({
              name: "Product",
              params: { id: response.data.id },
            });
          })
          .catch(function (err) {
            that.makeToast("Error", err.request.response, "danger");
          });
      }
    },

    makeToast(title, content, variant) {
      this.$bvToast.toast(content, {
        title,
        variant,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
  },
};
</script>
