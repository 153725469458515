<template>
  <div>
    <Nav></Nav>
    <b-container fluid class="mt-3">
      <ReportSearchBar :init="routeQuery.fini" :end="routeQuery.ffin" :sucursales="routeQuery.sucursales"
        :emitOnChange="false" @search="searchRecv" @changed="changeData"></ReportSearchBar>
      <b-row>
        <b-col>
          <h1>Ordenes de Producción</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button variant="success" size="sm" @click="$bvModal.show('modal-newManufacturingOrder')">Crear Orden de
            Produccion</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Buscar:">
            <b-form-input type="search" v-model="routeQuery.search" @keyup="page = 1" @update="updateQuery"
              debounce="500"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-table ref="selectableTable" selectable @row-selected="onRowSelected" small :filter="routeQuery.search"
            striped hover :items="manufacturingOrders" :fields="manufacturingOrdersLabels" responsive> <!---->
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(state)="data">
              <b-badge :variant="statusBadge(data.item.state).variant">{{ statusBadge(data.item.state).text
                }}</b-badge>
            </template>
            <template #cell(buttons)="data">
              <b-button size="sm" variant="success" @click="$router.push('/manufacturingorder/' + data.item.id)"
                class="mr-1">
                +Info
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-modal id="modal-newManufacturingOrder" :hide-footer="true" size="xl" title="Crear Orden de Manufactura">
        <b-row>
          <b-col>
            <b-form-group label="Sucursal">
              <vu-select :reduce="(x) => x.id" label="nombre" v-model="newManufacturingOrder.sucursal_id"
                :options="sucursales" @input="filterStockLocations"></vu-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Almacen Salida">
              <vu-select :reduce="(x) => x.id" label="complete_name" v-model="newManufacturingOrder.location_src_id"
                :options="filteredStockLocations"></vu-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Almacen Entrada">
              <vu-select :reduce="(x) => x.id" label="complete_name" v-model="newManufacturingOrder.location_dest_id"
                :options="filteredStockLocations"></vu-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Receta a Elaborar">
              <vu-select :reduce="(x) => x.id" label="name" @input="setMOName" v-model="newManufacturingOrder.bom_id"
                :options="boms"></vu-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Fecha inicio:">
              <b-form-input type="date" v-model="newManufacturingOrder.date_planned_start"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Fecha fin:">
              <b-form-input type="date" @input="setMOName"
                v-model="newManufacturingOrder.date_planned_finished"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Cantidad:">
              <b-form-input type="number" @input="setMOName" v-model.number="newManufacturingOrder.product_qty"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Nombre:"
              v-show="newManufacturingOrder.product_qty > 0 && newManufacturingOrder.date_planned_finished && newManufacturingOrder.bom_id > 0">
              <b-form-input type="text" v-model="newManufacturingOrder.name"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button variant="success" @click="createMO">Guardar</b-button>
          </b-col>
        </b-row>
      </b-modal>
    </b-container>

  </div>
</template>
<script>
import axios from "axios";
import Nav from "../components/Nav.vue";
import ReportSearchBar from "./reports/searchBar/ReportSearchBar.vue";
import moment from 'moment';
export default {
  name: "manufacturingOrders",
  components: {
    Nav, ReportSearchBar
  },

  data() {
    return {
      boms: [],
      allSucsArray: [],
      supplies: [],
      stockLocations: [],
      filteredStockLocations: [],
      sucursales: [],
      routeQuery: {
        page: null,
        search: null,
        fini: null,
        ffin: null,
        sucursales: null
      },
      initialDate: "",
      lastDate: "",
      search: "",
      manufacturingOrders: [],
      newManufacturingOrder: {
        name: null,
        bom_id: null,
        sucursal_id: null,
        product_qty: null,
        date_planned_start: null,
        date_planned_finished: null,
        location_src_id: null,
        location_dest_id: null
      },
      manufacturingOrdersLabels: [
        { key: "id", label: "ID" },
        { key: "name", label: "Nombre", sortable: true },
        { key: "location_src_id", label: "Desde", sortable: true },
        { key: "location_dest_id", label: "Hacia", sortable: true },
        { key: "supply_id", label: "Insumo", sortable: true },
        { key: "date_planned_start", label: "Fecha Plan Inicio", sortable: true },
        { key: "date_planned_finished", label: "Fecha Plan Fin", sortable: true },
        { key: "state", label: "Status", sortable: true },
        { key: "buttons", label: "" }
      ]
    };
  },

  computed: {

  },
  mounted() {
    console.log("mounted")
    this.routeQuery.search = this.$route.query.search
    this.routeQuery.ffin = this.$route.query.ffin
    this.routeQuery.fini = this.$route.query.fini
    this.routeQuery.sucursales = this.$route.query.sucursales
    console.log(this.routeQuery)
    this.getManufacturingOrders()
    this.getAllSucursales()
    this.getAllLocations()
    this.getBoms()
  },
  created() {
  },
  methods: {
    createMO() {
      let that = this
      axios.post("/manufacturingorder", this.newManufacturingOrder)
        .then(function (res) {
          that.$router.push('/manufacturingorder/' + res.data.id)
        }).catch(function (err) {
          console.log(err)
          that.makeToast("danger", "Error", err.response.data)
        })
    },
    setMOName() {
      let bom = this.boms.find((x) => x.id == this.newManufacturingOrder.bom_id)
      this.newManufacturingOrder.name = "MO/" + moment(this.newManufacturingOrder.date_planned_finished).format("YYYYMMDD") + "/" + bom.name + " X " + this.newManufacturingOrder.product_qty
    },
    filterStockLocations() {
      this.filteredStockLocations = this.stockLocations.filter((x) => x.sucursal_id == this.newManufacturingOrder.sucursal_id && x.type_usage == "internal")
    },
    onRowSelected(item) {
      console.log(item)
      if (item.length > 0) {
        this.$router.push('/manufacturingorder/' + item[0].id)
      }
      this.$refs.selectableTable.clearSelected()
    },
    updateQuery() {
      const pendingUpdates = { ...this.$route.query }
      Object.entries(this.routeQuery).forEach(([key, value]) => {
        if (value === null || value === undefined || value == "") {
          delete pendingUpdates[key]
        } else {
          pendingUpdates[key] = value
        }
      })
      this.$router.replace({
        query: pendingUpdates
      })
    },
    changeData(e) {
      console.log(e)
      this.routeQuery.ffin = moment(e.end).format('YYYY-MM-DD');
      this.routeQuery.fini = moment(e.init).format('YYYY-MM-DD');
      this.routeQuery.sucursales = e.sucursales.join(",");
      this.updateQuery()
    },
    searchRecv(p) {
      this.initialDate = moment(p.initDate).format('YYYY-MM-DD');
      this.lastDate = moment(p.endDate).format('YYYY-MM-DD');
      this.allSucsArray = p.sucursales
      this.getManufacturingOrders()
    },
    newPOError(evt) {
      this.getManufacturingOrders()
      this.makeToast("danger", "Error", evt.data)
    },
    statusBadge(status) {
      let rtn = { variant: "", text: status }
      switch (status) {
        case "draft":
          rtn.variant = "warning"
          rtn.text = "Borrador"
          break;
        case "confirmed":
          rtn.variant = "warning"
          rtn.text = "Confirmado"
          break;
        case "in_progress":
          rtn.variant = "info"
          rtn.text = "En Proceso"
          break;
        case "done":
          rtn.variant = "success"
          rtn.text = "Terminado"
          break;
        case "cancelled":
          rtn.variant = "danger"
          rtn.text = "Cancelado"
          break;
      }
      return rtn
    },
    newPOCreated(evt) {
      console.log(evt)
      this.$router.push('/manufacturingorder/' + evt)
      this.getManufacturingOrders()
    },
    getManufacturingOrders() {
      console.log("getManufacturingOrders")
      let that = this
      axios.get("/manufacturingorders?sucursales=" + this.allSucsArray.join(",") + "&init_date=" + this.initialDate + "&end_date=" + this.lastDate)
        .then(function (res) {
          that.manufacturingOrders = res.data
        }).catch(function (err) {
          console.error(err)
        })
    },
    getBoms() {
      let that = this
      axios.get("/boms")
        .then(function (res) {
          that.boms = res.data
        }).catch(function (err) {
          console.error(err)
        })
    },
    getAllSupplies() {
      let that = this;
      axios
        .get("/supplies")
        .then(function (response) {
          that.supplies = response.data;
        })
        .catch(function (err) {
          console.error(err)
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getAllSucursales() {
      let that = this;
      axios
        .get("/sucursals")
        .then(function (response) {
          console.log(response.data)
          that.sucursales = response.data;
        })
        .catch(function (err) {
          console.error(err)
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getAllLocations() {
      let that = this;
      axios
        .get("/stocklocations")
        .then(function (response) {
          that.stockLocations = response.data;
        })
        .catch(function (err) {
          console.error(err)
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    makeToast(variant = null, title = "", text = "") {
      this.$bvModal.msgBoxOk(text, {
        title: title,
        okVariant: variant,
        centered: true
      })
    },
  },
};
</script>
