<template>
  <div>
    <Nav></Nav>
    <b-container fluid="xl">
      <b-row>
        <b-col>
          <h1> Áreas de Preparación</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button variant="default" @click="$router.push('/preparationarea')">Crear Área</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Buscar:">
            <b-form-input
              type="search"
              v-model="search"
              @keyup="page = 1"
              debounce="500"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table small
          stacked="sm"
            :filter="search"
            striped
            hover
            :items="preparationareas"
            :fields="tableFields"
            responsive
            :per-page="byPage"
            :current-page="page"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(status)="data">
              <b-badge pill v-if="data.item.status" variant="success">ACTIVO</b-badge>
              <b-badge pill v-else-if="!data.item.status" variant="danger">INACTIVO</b-badge>
            </template>
             
            <template #cell(buttons)="data">
              <b-button-group vertical>
                <b-button size="sm" variant="warning" @click="$router.push('/preparationarea/' + data.item.id)"
                  >Editar</b-button
                >
              </b-button-group>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-pagination
            v-model="page"
            :total-rows="preparationareas.length"
            :per-page="byPage"
            aria-controls="my-table"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import Nav from "../components/Nav.vue";
import axios from "axios";
export default {
  name: "preparationareas",
  components: { Nav },
  data() {
    return {
      byPage: 20,
      page: 1,

      search: "",
      preparationareas: [],
      tableFields: [
        { key: "id", label: "ID", sortable: true, sortDirection: "desc",class:"align-middle" },
        { key: "name", label: "Nombre", sortable: true,class:"align-middle" },
        { key: "description", label: "Descripción", sortable: true ,class:"align-middle"},
        { key: "status",class:"align-middle" },
        { key: "buttons" ,class:"align-middle"},
      ],
    };
  },
  mounted() {
    this.getPreparationareas();
  },
  methods: {
    getPreparationareas() {
      let that = this;
      axios
        .get("/preparationareas")
        .then(function (response) {
          that.preparationareas = response.data;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    
    makeToast(title, content, variant) {
      this.$bvToast.toast(content, {
        title,
        variant,
        autoHideDelay: 5000,  
        appendToast: true,
      });
    },
  },
};
</script>
