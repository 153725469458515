<template>
  <div>
    <Nav></Nav>
    <b-container fluid class="mt-3">
      <b-row>
        <b-col>
          <h1>Proveedores</h1>
        </b-col>
      </b-row>
      <b-row class="text-left my-2">
        <b-col>
          <b-button variant="success" @click="$bvModal.show('modal-newProvider')" size="sm mr-3">Crear Proveedor
            +</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Buscar:">
            <b-form-input type="search" v-model="search" @keyup="page = 1" debounce="500"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table small :per-page="byPage" :current-page="page" :filter="search" striped hover :items="supply_list" :fields="comprasLabels" responsive>
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(status)="data">
              <b-badge :variant="statusBadge(data.item.status).variant">{{ statusBadge(data.item.status).text
                }}</b-badge>
            </template>
            <template #cell(buttons)="data">
              <b-button size="sm" variant="success"
                @click="getProvider(data.item.id); $bvModal.show('modal-newProvider')" class="mr-1">
                +Info
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-pagination v-model="page" :total-rows="supply_list.length" :per-page="byPage"
            aria-controls="my-table"></b-pagination>
        </b-col>
      </b-row>
    </b-container>
    <b-modal id="modal-newProvider" @close="emptyNewItem" :hide-footer="true" size="xl"
      :title="(newItem.id > 0 ? 'Editar' : 'Agregar') + ' Proveedor'">
      <b-row>
        <b-col lg="6" cols="12">
          <b-form-group label="Nombre" description="Introduce el nombre">
            <b-form-input v-model="newItem.name" placeholder="Nombre" required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col lg="6" cols="12">
          <b-form-group label="Nombre Comercial">
            <b-form-input v-model="newItem.name" placeholder="Nombre Comercial" required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col lg="6" cols="12">
          <b-form-group label="Direccion 1">
            <b-form-input v-model="newItem.street" required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col lg="6" cols="12">
          <b-form-group label="Direccion 2">
            <b-form-input v-model="newItem.street2" required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col lg="6" cols="12">
          <b-form-group label="Ciudad">
            <b-form-input v-model="newItem.city" required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col lg="6" cols="12">
          <b-form-group label="Codigo Postal">
            <b-form-input v-model="newItem.zip" required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col lg="6" cols="12">
          <b-form-group label="Mail">
            <b-form-input v-model="newItem.email" required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col lg="6" cols="12">
          <b-form-group label="Telefono">
            <b-form-input v-model="newItem.phone" required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col lg="6" cols="12">
          <b-form-group label="Sitio Web">
            <b-form-input v-model="newItem.website" required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col lg="6" cols="12">
          <b-form-group label="Proveedor Interno">
            <b-form-checkbox v-model="newItem.is_internal" :value="true"
              :unchecked-value="false">
              Marca la casilla si es un proveedor interno
            </b-form-checkbox> </b-form-group>
        </b-col>
        <b-col v-show="newItem.is_internal" lg="6" cols="12">
          <b-form-group label="Introduce el ID de sucursal ">
            <b-form-input v-model="newItem.sucursal_id" required></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="my-3">
          <b-button class="mr-3" @click="createNewItem" variant="success">Guardar</b-button>
          <b-button class="mr-3" @click="$bvModal.hide('modal-newProvider'); emptyNewItem();"
            variant="danger">Cancelar</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
import Nav from "../components/Nav.vue";
import router from "../router"
import moment from 'moment';

export default {
  name: "SupplyLists",
  components: {
    Nav
  },

  data() {
    return {
      page:1,
      byPage:20,
      newItem: {
        name: '',
        description: ''
      },
      initialDate: "",
      lastDate: "",
      allSucsArray: [],
      search: "",
      supply_list: [],
      actualSucursal: {
        id: null
      },
      comprasLabels: [
        { key: "id", label: "ID" },
        { key: "name", label: "Nombre" },
        { key: "buttons", label: "" }
      ]
    };
  },

  computed: {

  },
  watch: {},
  mounted() {
    this.getLists()
  },
  created() {

  },
  methods: {
    createNewItem() {
      let that = this
      let elm = this.newItem
      if (!this.newItem.is_internal){
        this.newItem.sucursal_id=null
      }
      if(this.newItem.id>0){
        axios.put("/provider/"+this.newItem.id, elm)
        .then(function (res) {
          if (res.data.id > 0) {
            that.getLists()
            that.emptyNewItem()
            that.$bvModal.hide("modal-newProvider")
          }
        }).catch(function (err) {
          that.makeToast("danger", "Error", err.response.data)
        })
      }else{
        axios.post("/provider", elm)
        .then(function (res) {
          if (res.data.id > 0) {
            that.getLists()
            that.emptyNewItem()
            that.$bvModal.hide("modal-newProvider")
          }
        }).catch(function (err) {
          that.makeToast("danger", "Error", err.response.data)
        })
      }
      

    },
    emptyNewItem() {
      this.newItem = {
        name: '',
        description: ''
      }
    },
    searchRecv(p) {
      this.initialDate = moment(p.initDate).format('YYYY-MM-DD');
      this.lastDate = moment(p.endDate).format('YYYY-MM-DD');
      this.allSucsArray = p.sucursales
      this.getLists()
    },
    newPOError(evt) {
      console.log("error reading")
      console.log(evt)
      this.getLists()
      this.makeToast("danger", "Error", evt.data)
    },
    statusBadge(status) {
      let rtn = { variant: "", text: status }
      switch (status) {
        case "DRAFT":
          rtn.variant = "warning"
          rtn.text = "Borrador"
          break;
        case "SENT":
          rtn.variant = "success"
          rtn.text = "Enviado"
          break;
        case "CANCELED":
          rtn.variant = "danger"
          rtn.text = "Cancelado"
          break;
      }
      return rtn
    },
    newPOCreated(evt) {
      console.log(evt)
      router.push('/compra/' + evt)
      this.getLists()
    },
    getProvider(id) {
      let that = this
      axios.get("/provider/" + id)
        .then(function (res) {
          that.newItem = res.data
        }).catch(function (err) {
          console.error(err)
        })
    },
    getLists() {
      let that = this
      axios.get("/providers")
        .then(function (res) {
          that.supply_list = res.data
        }).catch(function (err) {
          console.error(err)
        })
    },
    makeToast(variant = null, title = "", text = "") {
      this.$bvModal.msgBoxOk(text, {
        title: title,
        okVariant: variant,
        centered: true
      })
    },
  },
};
</script>
