<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col>
          <h2>Personalizations:</h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button size="sm" variant="success" @click="createUberPersonalization">Crear
            Item</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table small responsive striped hover :items="personalizations" :fields="tableFields">
            <template #cell(buttons)="data">
              <b-button-group vertical>
                <b-button size="sm" variant="warning"
                  @click="editPersonalization(data.item.id)">Editar</b-button>
              </b-button-group>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-container>
    <b-modal id="modal-createUberPersonalization" title="Crear Categoria" size="xl" @ok="createPersonalization">
      <b-row>
        <b-col>
          <p>Aqui puedes crear tus personalizaciones.</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Nombre:">
            <b-form-input v-model="actualItem.title.translations.en" type="text" required></b-form-input>
          </b-form-group>
          <b-form-group label="¿Cuál es el número mínimo de opciones que un cliente debe seleccionar?">
            <b-form-input v-model.number="actualItem.quantity_info.quantity.min_permitted" type="number" required></b-form-input>
          </b-form-group>
          <b-form-group label="¿Cuál es el número máximo de opciones que un cliente puede seleccionar?">
            <b-form-input v-model.number="actualItem.quantity_info.quantity.max_permitted" type="number" required></b-form-input>
          </b-form-group>
          <b-form-group label="¿Cuántas veces pueden seleccionar los clientes una sola opción?">
            <b-form-input v-model.number="actualItem.quantity_info.quantity.min_permitted_unique" type="number" required></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "UberPersonalizations",
  data() {
    return {
      products: [],
      productOptions: [],
      personalizations: [],
      actualItem: {
        id: "",
        title: {
          translations: {
            en: ""
          }
        },
        quantity_info: {
          quantity: {
            max_permitted: 1,
            min_permitted:1,
            is_min_permitted_optional:false,
            min_permitted_unique:1
          },
          overrides: []
        },
        modifier_options: [],
      },
      search: "",
      tableFields: [
        { key: "title.translations.en", label: "Titulo" },
        { key: "price_info.price", label: "Precio" },
        { key: "buttons", label: "" }
      ]
    };
  },
  mounted() {
    this.getAllPersonalizations();
    this.getAllProducts()
  },
  methods: {
    createUberPersonalization() {
      this.resetactualPersonalization()
      this.$bvModal.show("modal-createUberPersonalization")
    },
    getAllProducts() {
      let that = this;
      axios
        .get("/products")
        .then(function (response) {
          that.products = response.data;
          let persfil = response.data
            .filter((pers) => {
              return pers.status;
            })
            .map((pers) => {
              return { id: pers.id.toString(), label: pers.clave + " - " + pers.ndescription };
            });
          let persfils = [{ id: null, label: "NINGUNO" }]
          persfils.push(...persfil)
          that.productOptions = persfils;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    editPersonalization(id) {
      let that = this;
      this.actualItem.id = id
      axios
        .get("/uber/mastermenu/" + this.$route.params.id + "/modifiergroup/" + this.actualItem.id)
        .then(function (response) {
          that.actualItem = response.data;
          that.$bvModal.show("modal-createUberPersonalization")
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getAllPersonalizations() {
      let that = this;
      axios
        .get("/uber/mastermenu/" + this.$route.params.id + "/modifiergroups")
        .then(function (response) {
          that.personalizations = response.data;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    createPersonalization() {
      let that = this;
      let verb = ""
      if (this.actualItem.id.length > 0) {
        verb = "PUT"
      } else {
        verb = "POST"
      }
      console.log(verb)
      axios({ method: verb, url: "/uber/mastermenu/" + this.$route.params.id + "/modifiergroup", data: this.actualItem })
        .then(function (response) {
          that.actualItem = response.data;
          that.getAllPersonalizations()
          that.resetactualPersonalization()
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getPersonalization() {
      let that = this;
      axios
        .get("/uber/mastermenu/" + this.$route.params.id + "/personalization/" + this.actualItem.id)
        .then(function (response) {
          that.actualItem = response.data;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    resetactualPersonalization() {
      this.actualItem = {
        id: "",
        title: {
          translations: {
            en: ""
          }
        },
        quantity_info: {
          quantity: {
            max_permitted: 1,
            min_permitted:1,
            is_min_permitted_optional:false,
            min_permitted_unique:1
          },
          overrides: []
        },
        modifier_options: [],
      }
    },
    makeToast(title, content, variant) {
      this.$bvToast.toast(content, {
        title,
        variant,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
  },
};
</script>
  