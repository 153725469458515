<template>
    <div>
        <Nav></Nav>
        <b-container fluid="xl">
            <b-row>
                <b-col>
                    <h1>Grupos de Usuarios</h1>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-button variant="default" @click="$router.push('/userGroup')"> Crear Grupo de Usuarios </b-button>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Buscar: "> 
                        <b-form-input
                        type="search"
                        v-model="search"
                        @keyup="page= 1"
                        debounce="500">
                        </b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-table small
                    :filter="search"
                    striped
                    hover
                    :items="discountsTable"
                    :fields="tableFields"
                    responsive
                    :per-page="byPage"
                    :current-page="page">
                        <template #table-busy>
                            <div class="text-center text-danger my-2">
                                <b-spinner class="align-middle"> </b-spinner>
                                <strong>Loading ... </strong>
                            </div>
                        </template>

                        <template #cell(status)="data">
                            <b-badge pill v-if="data.item.status" variant="success">ACTIVO</b-badge>
                            <b-badge pill v-else-if="!data.item.status" variant="danger">INACTIVO</b-badge>
                        </template >

                        <template #cell(buttons)="data">
                            <b-button-group v-if="data.item.company_id!=null" vertical>
                             <b-button size="sm" variant="warning" @click="$router.push('/userGroup/' + data.item.id)">Editar</b-button>
                            </b-button-group>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-pagination
                    v-model="page"
                    :total-rows="discountsTable.length"
                    :per-page="byPage"
                    aria-controls="my-table">
                    </b-pagination>
                </b-col>
            </b-row>

        </b-container>
    </div>
</template>


<script>


import Nav from "../components/Nav.vue";
import axios from "axios";
export default {
  name: "userGroups",
  components: { Nav },
  data() {
    return {
      byPage:20,
      page:1,
      discountsTable:[],
      tableFields: [
        { key: "id", label: "ID", sortable: true, sortDirection: "desc" },
        { key: "name", label: "Nombre", sortable: true },
        { key: "buttons", sortable: true }
      ],
      search: "",
    };
  },
  mounted(){
    this.getUserGroups();
  },
  methods:{
    getUserGroups(){
        let that=this;
        axios
        .get("/permission/groups")
        .then(function (response) {
          that.discountsTable = response.data;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    }
  },
  makeToast(title, content, variant) {
      this.$bvToast.toast(content, {
        title,
        variant,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
  
};


</script>